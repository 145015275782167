const surveyMap = {
  // Scoring
  dryness_skin: 'dryness',
  hyperpigmentation_skin: 'hyperpigmentation',
  oiliness_skin: 'oiliness',
  sensitivity_skin: 'sensitivity',
  stressors_skin: 'stressors',
  skin_barrier_skin: 'barrier',
  skin_maturity_skin: 'maturity',
};

export default surveyMap;
