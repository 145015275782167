import { createSlice } from '@reduxjs/toolkit';

import {
  fetchCustomer,
  fetchHairProfile,
  patchHairProfile,
  signinCustomer,
  signupCustomer,
} from './thunks';
import type { FastConsultationState } from './types';

const initialState: FastConsultationState = {
  status: 'idle',
  error: null,
  customer: null,
  hairProfile: null,
  snackbar: {
    message: null,
    severity: null,
  },
  accessToken: null,
  refreshToken: null,
};

const fastConsultationSlice = createSlice({
  name: 'fastconsultation',
  initialState,
  reducers: {
    clearSnackbar(draftState) {
      draftState.snackbar = {
        message: null,
        severity: null,
      };
    },
  },
  extraReducers: builder => {
    builder
      // fetchCustomer
      .addCase(fetchCustomer.pending, draftState => {
        draftState.status = 'pending';
        draftState.error = null;
        draftState.customer = null;
        draftState.hairProfile = null;
      })
      .addCase(fetchCustomer.fulfilled, (draftState, action) => {
        const customer = action.payload;

        draftState.status = 'fulfilled';
        draftState.customer = customer;
      })
      .addCase(fetchCustomer.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
        draftState.snackbar = {
          severity: 'error',
          message: 'Error when fetching customer profile',
        };
      })
      // signinCustomer
      .addCase(signinCustomer.pending, draftState => {
        draftState.status = 'pending';
        draftState.error = null;
      })
      .addCase(signinCustomer.fulfilled, (draftState, action) => {
        const { accessToken, refreshToken } = action.payload;

        draftState.status = 'fulfilled';
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
      })
      .addCase(signinCustomer.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.customer = null;
        draftState.hairProfile = null;
        draftState.snackbar = { severity: 'error', message: 'Error during authentication' };
      })
      // signupCustomer
      .addCase(signupCustomer.pending, draftState => {
        draftState.status = 'pending';
        draftState.error = null;
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.customer = null;
        draftState.hairProfile = null;
      })
      .addCase(signupCustomer.fulfilled, (draftState, action) => {
        const { accessToken, refreshToken } = action.payload;

        draftState.status = 'fulfilled';
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
      })
      .addCase(signupCustomer.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.customer = null;
        draftState.hairProfile = null;
        draftState.snackbar = { severity: 'error', message: 'Error during authentication' };
      })
      // fetchHairProfile
      .addCase(fetchHairProfile.pending, draftState => {
        draftState.status = 'pending';
        draftState.hairProfile = null;
      })
      .addCase(fetchHairProfile.fulfilled, (draftState, action) => {
        const hairProfile = action.payload;

        draftState.status = 'fulfilled';
        draftState.hairProfile = hairProfile;
      })
      .addCase(fetchHairProfile.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
        draftState.snackbar = { severity: 'error', message: 'Error when fetching hair profile' };
      })
      // patchHairProfile
      .addCase(patchHairProfile.pending, draftState => {
        draftState.status = 'pending';
        draftState.error = null;
      })
      .addCase(patchHairProfile.fulfilled, (draftState, action) => {
        const { hairProfile, category } = action.payload;

        draftState.status = 'fulfilled';
        draftState.hairProfile = hairProfile;
        draftState.snackbar = { severity: 'success', message: `${category} profile saved` };
      })
      .addCase(patchHairProfile.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
        draftState.snackbar = { severity: 'error', message: 'Error when patching hair profile' };
      });
  },
});

const { reducer, actions } = fastConsultationSlice;

export const { clearSnackbar } = actions;

export default reducer;
