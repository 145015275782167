import styled from '@mui/material/styles/styled';

const defaults = {
  fontFamily: 'inherit',
  backgroundColor: null,
  backgroundImage: null,
  backgroundColorOpacityHex: '8a',
  backgroundPosition: '50% 50%',
};

const getBackgroundColor = theme => {
  if (theme.page?.backgroundColor && theme.page?.backgroundImage) {
    return `${theme.page?.backgroundColor}${
      theme.page?.backgroundColorOpacityHex || defaults.backgroundColorOpacityHex
    }`;
  }
  return theme.page?.backgroundColor || defaults.backgroundColor;
};

const PageRoot = styled('div')(
  ({ theme }) => `
  --page-padding: max(24px, 2.2vw);

  grid-column: 2 / -1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  padding: var(--page-padding);
  overflow: hidden auto;

  font-family: ${theme.page?.fontFamily || defaults.fontFamily};

  background-color: ${getBackgroundColor(theme)};

  /* pseudo-element for semi-transparent background-color over full opacity background-image */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    background-size: cover;
    background-image: ${`url(${theme.page?.backgroundImage})` || defaults.backgroundImage};
    background-position: ${theme.page?.backgroundPosition || defaults.backgroundImage};
  }
`
);

export default PageRoot;
