import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import reduce from 'lodash/fp/reduce';
import set from 'lodash/fp/set';

import type { Coupon, CouponsDictionary } from '../coupons/types';

const transformCoupons = (coupons: Array<Coupon>): CouponsDictionary => {
  return reduce(
    (acc: CouponsDictionary, curr: Coupon) => {
      const { pubkey, coupon: couponDetail } = curr;
      return {
        coupons: {
          ...acc.coupons,
          [pubkey]: flow(omit('coupon'), set('couponDetailsRef', couponDetail.pubkey))(curr),
        },
        couponDetails: {
          ...acc.couponDetails,
          [couponDetail.pubkey]: {
            ...couponDetail,
            parentPubkey: get('issues_as_issued.0.parent_coupon', couponDetail),
            childPubkey: get('issues_as_parent.0.issued_coupon', couponDetail),
            couponRef: pubkey,
          },
        },
      };
    },
    {
      coupons: {},
      couponDetails: {},
    },
    coupons
  );
};
export default transformCoupons;
