import { createSlice } from '@reduxjs/toolkit';

import { fetchFormulaset, fetchScoring, fetchSurvey } from './thunks';
import type { SurveysState } from './types';

const initialState: SurveysState = {
  surveysList: [],
  survey: null,
  formulaset: null,
  scorings: null,
  status: 'idle',
  error: null,
  pages: {},
  notification: {
    message: null,
    severity: null,
  },
};

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSurvey.pending, draftState => {
        draftState.status = 'pending';
        draftState.survey = null;
        draftState.error = null;
      })
      .addCase(fetchSurvey.fulfilled, (draftState, action) => {
        const { survey } = action.payload;

        draftState.status = 'fulfilled';
        draftState.survey = survey;
      })
      .addCase(fetchSurvey.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchFormulaset.pending, draftState => {
        draftState.status = 'pending';
        draftState.formulaset = null;
        draftState.error = null;
      })
      .addCase(fetchFormulaset.fulfilled, (draftState, action) => {
        const formulaset = action.payload;

        draftState.status = 'fulfilled';
        draftState.formulaset = formulaset;
      })
      .addCase(fetchFormulaset.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchScoring.pending, draftState => {
        draftState.status = 'pending';
        draftState.scorings = null;
        draftState.error = null;
      })
      .addCase(fetchScoring.fulfilled, (draftState, action) => {
        const scoring = action.payload;

        draftState.status = 'fulfilled';
        draftState.scorings = scoring;
      })
      .addCase(fetchScoring.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = surveysSlice;

export default reducer;
