import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { blue, green, grey, yellow } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import type { Nullable } from 'types/common';

const drawerWidth = '240px';

export const StyledAppBar = styled(AppBar)`
  position: initial;
  grid-column: 2 / -1;
  grid-row: 1 / 2;
  height: fit-content;
`;

export const StyledToolbar = styled(Toolbar)<{ sectiontitle: Nullable<string> }>`
  padding-right: 0;
  background-color: ${({ sectiontitle }) => {
    if (sectiontitle === 'Customers') return blue[900];
    if (sectiontitle === 'Orders') return green[900];
    if (sectiontitle === 'Surveys') return yellow[900];
    return grey[900];
  }};
`;

export const AppBarVersionInfo = styled('div')`
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const IconMenuButton = styled(IconButton)`
  margin: 0 12px;
  & svg {
    fill: white;
    width: 36px;
    height: 36px;
  }
`;

export const StyledDrawer = styled(Drawer)`
  grid-column: 1 / 2;
  grid-row: 1 / -1;

  & > div {
    position: initial;
    overflow-x: hidden;
    width: ${({ open }) => (open ? drawerWidth : '59px')};
    transition: ${({ theme, open }) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: open
          ? theme.transitions.duration.leavingScreen
          : theme.transitions.duration.enteringScreen,
      })};
    & ul {
      padding-top: 20px;
      & span {
        white-space: nowrap; /* to handle more-than-one-word ListItemTexts */
      }
    }
  }
`;

export const OpenNavButton = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ${({ theme }) => theme.mixins.toolbar}
`;

export const AppBarSectionTitle = styled('div')`
  flex: 1;
  & h6 {
    font-weight: 500;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex: 0;
  }
`;

export const AppBarSearch = styled('div')`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.15)};
  flex: 2;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.25)};
  }
  margin-left: 0;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-right: ${({ theme }) => theme.spacing(1)};
    width: auto;
  }
`;

export const AppBarInfo = styled(AppBarSectionTitle)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  font-size: 30px;
`;

export const SeparationLine = styled('div')`
  margin: 30px 10px 5px;
  border-top: 1px solid black;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 8px 16px;
  &:hover {
    background-color: transparent;
  }
`;
