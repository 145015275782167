import React from 'react';

import { Route, Routes } from 'react-router-dom';

import HomeScene from '../Scenes/Home';

import * as PERMISSIONS from 'utils/teammatesAccess/teammatesPermissions';

import PermissionRoute from './PermissionRoute';

const CustomersApp = React.lazy(() => import('../Apps/Customers'));
const CouponsApp = React.lazy(() => import('../Apps/Coupons'));
const ExportsApp = React.lazy(() => import('../Apps/Exports'));
const FastConsultationApp = React.lazy(() => import('../Apps/FastConsultation'));
const FeedbacksApp = React.lazy(() => import('../Apps/Feedbacks'));
const GiftsApp = React.lazy(() => import('../Apps/Gifts'));
const HelpApp = React.lazy(() => import('../Apps/Help'));
const OrdersApp = React.lazy(() => import('../Apps/Orders'));
const AdminPanelApp = React.lazy(() => import('../Apps/AdminPanel'));
const ProductionApp = React.lazy(() => import('../Apps/Production'));
const SettingsApp = React.lazy(() => import('../Apps/Settings'));
const SubscriptionsApp = React.lazy(() => import('../Apps/Subscriptions'));
const SurveysApp = React.lazy(() => import('../Apps/Surveys'));
const TeammatesApp = React.lazy(() => import('../Apps/Teammates'));
const DataPortalApp = React.lazy(() => import('../Apps/DataPortal'));

const TempRouteComponent = () => (
  <Routes>
    <Route
      element={
        <PermissionRoute permissions={[PERMISSIONS.CUSTOMER_VIEW, PERMISSIONS.CUSTOMER_LIST]} />
      }
    >
      <Route path="/customers/*" element={<CustomersApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.COUPONS_VIEW]} />}>
      <Route path="/coupons/*" element={<CouponsApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.EXPORTS]} />}>
      <Route path="/exports/*" element={<ExportsApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.FAST_CONSULTATION]} />}>
      <Route path="/fastconsultation/*" element={<FastConsultationApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.FEEDBACK_LIST]} />}>
      <Route path="/feedbacks/*" element={<FeedbacksApp />} />
    </Route>
    <Route
      element={<PermissionRoute permissions={[PERMISSIONS.GIFT_DETAIL, PERMISSIONS.GIFT_LIST]} />}
    >
      <Route path="/gifts/*" element={<GiftsApp />} />
    </Route>
    <Route
      element={
        <PermissionRoute
          permissions={[
            PERMISSIONS.HELP,
            PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
            PERMISSIONS.HELP_STATUS_VIEWER,
          ]}
        />
      }
    >
      <Route path="/help/*" element={<HelpApp />} />
    </Route>
    <Route
      element={<PermissionRoute permissions={[PERMISSIONS.ORDER_LIST, PERMISSIONS.ORDER_VIEW]} />}
    >
      <Route path="/orders/*" element={<OrdersApp />} />
    </Route>
    <Route
      element={
        <PermissionRoute
          permissions={[
            PERMISSIONS.PRODUCTION_LIST,
            PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
            PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
            PERMISSIONS.AUTO_DISPATCH,
            PERMISSIONS.BUFFER_SPACES_LIST,
            PERMISSIONS.MANUAL_DISPATCH,
            PERMISSIONS.PACK_SCREEN,
          ]}
        />
      }
    >
      <Route path="/production/*" element={<ProductionApp />} />
    </Route>
    <Route
      element={
        <PermissionRoute
          permissions={[PERMISSIONS.SUBSCRIPTION_LIST, PERMISSIONS.SUBSCRIPTION_VIEW]}
        />
      }
    >
      <Route path="/subscriptions/*" element={<SubscriptionsApp />} />
    </Route>
    <Route
      element={
        <PermissionRoute
          permissions={[
            PERMISSIONS.CUSTOMER_LIST,
            PERMISSIONS.CUSTOMER_VIEW,
            PERMISSIONS.ORDER_LIST,
            PERMISSIONS.ORDER_VIEW,
            PERMISSIONS.SUBSCRIPTION_LIST,
            PERMISSIONS.SUBSCRIPTION_VIEW,
            PERMISSIONS.FEEDBACK_LIST,
            PERMISSIONS.GIFT_LIST,
            PERMISSIONS.GIFT_DETAIL,
            PERMISSIONS.PRODUCTION_LIST,
            PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
            PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
            PERMISSIONS.PACK_SCREEN,
            PERMISSIONS.AUTO_DISPATCH,
            PERMISSIONS.MANUAL_DISPATCH,
            PERMISSIONS.BUFFER_SPACES_LIST,
            PERMISSIONS.EXPORTS,
            PERMISSIONS.DATA_PORTAL,
            PERMISSIONS.TEAMMATES_LIST,
            PERMISSIONS.TEAMMATES_DETAIL,
            PERMISSIONS.TEAMMATES_EDIT,
            PERMISSIONS.FAST_CONSULTATION,
            PERMISSIONS.HELP,
            PERMISSIONS.HELP_STATUS_VIEWER,
            PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
            PERMISSIONS.SALON_LIST,
            PERMISSIONS.SALON_VIEW,
          ]}
        />
      }
    >
      <Route path="/settings/*" element={<SettingsApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.SURVEY_VIEW]} />}>
      <Route path="/surveys/*" element={<SurveysApp />} />
    </Route>
    <Route
      element={
        <PermissionRoute
          permissions={[
            PERMISSIONS.TEAMMATES_DETAIL,
            PERMISSIONS.TEAMMATES_LIST,
            PERMISSIONS.TEAMMATES_EDIT,
          ]}
        />
      }
    >
      <Route path="/teammates/*" element={<TeammatesApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.DATA_PORTAL]} />}>
      <Route path="/dataportal/*" element={<DataPortalApp />} />
    </Route>
    <Route element={<PermissionRoute permissions={[PERMISSIONS.PRINTERS_ADMIN]} />}>
      <Route path="/adminPanel/*" element={<AdminPanelApp />} />
    </Route>
    <Route path="/" element={<HomeScene />} />
  </Routes>
);

export default TempRouteComponent;
