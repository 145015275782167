export const loadParam = name => {
  const value = localStorage.getItem(name);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (err) {
      localStorage.removeItem(name);
      return null;
    }
  }
  return value;
};

export const saveParam = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const clearParam = name => {
  localStorage.removeItem(name);
};
