import { createSlice } from '@reduxjs/toolkit';

import type { GiftsState } from './types/slice-types';
import { fetchGift, fetchGifts, fetchGiftsStats, updateGift } from './thunks';

const initialState: GiftsState = {
  status: 'idle',
  updateGiftStatus: 'idle',
  gifts: [],
  currentGift: null,
  error: null,
  updateGiftError: null,
  pages: {},
  stats: null,
};

const giftsSlice = createSlice({
  name: 'gifts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // fetchGifts
      .addCase(fetchGifts.pending, draftState => {
        draftState.status = 'pending';
        draftState.gifts = [];
        draftState.pages = {};
        draftState.error = null;
      })
      .addCase(fetchGifts.fulfilled, (draftState, action) => {
        const { gifts, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.gifts = gifts;
        draftState.pages = pages;
      })
      .addCase(fetchGifts.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // fetchGift
      .addCase(fetchGift.pending, draftState => {
        draftState.status = 'pending';
        draftState.currentGift = null;
        draftState.error = null;
      })
      .addCase(fetchGift.fulfilled, (draftState, action) => {
        const gift = action.payload;

        draftState.status = 'fulfilled';
        draftState.currentGift = gift;
      })
      .addCase(fetchGift.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // updateGift
      .addCase(updateGift.pending, draftState => {
        draftState.updateGiftStatus = 'pending';
        // not setting current gift here to null to avoid splashing (mimicking "stale while revalidate" behavior)
        draftState.updateGiftError = null;
      })
      .addCase(updateGift.fulfilled, (draftState, action) => {
        const updatedGift = action.payload;

        draftState.updateGiftStatus = 'fulfilled';
        draftState.currentGift = updatedGift;
      })
      .addCase(updateGift.rejected, (draftState, action) => {
        draftState.updateGiftStatus = 'rejected';
        draftState.updateGiftError = action.error;
      })
      // fetchGiftsStats
      .addCase(fetchGiftsStats.pending, draftState => {
        draftState.status = 'pending';
        draftState.stats = null;
      })
      .addCase(fetchGiftsStats.fulfilled, (draftState, action) => {
        const stats = action.payload;

        draftState.status = 'fulfilled';
        draftState.stats = stats;
      })
      .addCase(fetchGiftsStats.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = giftsSlice;

export default reducer;
