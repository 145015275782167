import get from 'lodash/fp/get';
import identity from 'lodash/fp/identity';
import indexOf from 'lodash/fp/indexOf';
import size from 'lodash/fp/size';
import sortBy from 'lodash/fp/sortBy';

const defaultProductsOrder = [
  'shampoo',
  'conditioner',
  'hair_mask',
  'scalp_mask',
  'leavein',
  'dry_shampoo',
  'leavein_conditioner',
  'curl_cream',
  'styling_gel',
  'supplement_booster',
  'supplement_core',
  'cleanser',
  'moisturizer',
  'serum',
];

// Highly re-usable version that defaults to identity
export const sortInSpecificOrder = (getterFunction = identity, orderStringArray = []) =>
  sortBy(entry => {
    const index = indexOf(getterFunction(entry), orderStringArray);
    return index === -1 ? size(orderStringArray) : index;
  });

// Shape dependant and order dependant sort function
export const sortFormulasInSpecificProductsOrder = sortInSpecificOrder(
  get('skeleton.type'),
  defaultProductsOrder
);

// Order dependant sort function
export const sortInSpecificProductsOrder = sortInSpecificOrder(identity, defaultProductsOrder);

export const sortInDisplayOrder = sortInSpecificOrder(
  product => product?.type ?? product?.variant?.product?.type,
  defaultProductsOrder
);
