export const PRODUCTION_FETCH_LANES_START = 'PRODUCTION_FETCH_LANES_START';
export const PRODUCTION_FETCH_LANES_SUCCESS = 'PRODUCTION_FETCH_LANES_SUCCESS';
export const PRODUCTION_FETCH_LANES_ERROR = 'PRODUCTION_FETCH_LANES_ERROR';

export const PRODUCTION_FETCH_ALL_LANES_START = 'PRODUCTION_FETCH_ALL_LANES_START';
export const PRODUCTION_FETCH_ALL_LANES_SUCCESS = 'PRODUCTION_FETCH_ALL_LANES_SUCCESS';

export const PRODUCTION_SET_ACTION = 'PRODUCTION_SET_ACTION';
export const PRODUCTION_SET_LANE = 'PRODUCTION_SET_LANE';
export const PRODUCTION_SET_FACTORY = 'PRODUCTION_SET_FACTORY';

export const PRODUCTION_FETCH_BOX_FROM_BIN_ERROR = 'PRODUCTION_FETCH_BOX_FROM_BIN_ERROR';
export const PRODUCTION_FETCH_BOX_FROM_BIN_START = 'PRODUCTION_FETCH_BOX_FROM_BIN_START';
export const PRODUCTION_FETCH_BOX_FROM_BIN_SUCCESS = 'PRODUCTION_FETCH_BOX_FROM_BIN_SUCCESS';
export const PRODUCTION_FETCH_BOX_ERROR = 'PRODUCTION_FETCH_BOX_ERROR';
export const PRODUCTION_SET_BOXES_START = 'PRODUCTION_SET_BOXES_START';
export const PRODUCTION_SET_BOXES_SUCCESS = 'PRODUCTION_SET_BOXES_SUCCESS';
export const PRODUCTION_SET_BOXES_ERROR = 'PRODUCTION_SET_BOXES_ERROR';

export const PRODUCTION_SEARCH_BOX_START = 'PRODUCTION_SEARCH_BOX_START';
export const PRODUCTION_SEARCH_BOX_SUCCESS = 'PRODUCTION_SEARCH_BOX_SUCCESS';
export const PRODUCTION_SEARCH_BOX_ERROR = 'PRODUCTION_SEARCH_BOX_ERROR';

export const PRODUCTION_MANUAL_START_BOX_START = 'PRODUCTION_MANUAL_START_BOX_START';
export const PRODUCTION_MANUAL_START_BOX_SUCCESS = 'PRODUCTION_MANUAL_START_BOX_SUCCESS';
export const PRODUCTION_MANUAL_START_BOX_ERROR = 'PRODUCTION_MANUAL_START_BOX_ERROR';

export const PRODUCTION_FETCH_BOX_START = 'PRODUCTION_FETCH_BOX_START';
export const PRODUCTION_FETCH_BOX_SUCCESS = 'PRODUCTION_FETCH_BOX_SUCCESS';
export const PRODUCTION_EMPTY_BOX = 'PRODUCTION_EMPTY_BOX';
export const PRODUCTION_RESET_SCAN = 'PRODUCTION_RESET_SCAN';
export const PRODUCTION_SET_COMPLEMENTARY_ITEMS = 'PRODUCTION_SET_COMPLEMENTARY_ITEMS';
export const PRODUCTION_ON_SCAN_PRODUCT_START = 'PRODUCTION_ON_SCAN_PRODUCT_START';
export const PRODUCTION_ON_SCAN_PRODUCT_SUCCESS = 'PRODUCTION_ON_SCAN_PRODUCT_SUCCESS';
export const PRODUCTION_LABELING_ON_SCAN_PRODUCT = 'PRODUCTION_LABELING_ON_SCAN_PRODUCT';

export const PRODUCTION_ON_SCAN_PRODUCT_ERROR = 'PRODUCTION_ON_SCAN_PRODUCT_ERROR';
export const PRODUCTION_ON_PRINT_PREPACK_START = 'PRODUCTION_ON_PRINT_PREPACK_START';
export const PRODUCTION_ON_PRINT_PREPACK_SUCCESS = 'PRODUCTION_ON_PRINT_PREPACK_SUCCESS';
export const PRODUCTION_ON_PRINT_PREPACK_ERROR = 'PRODUCTION_ON_PRINT_PREPACK_ERROR';
export const PRODUCTION_ON_CLICK_ACTION_BUTTON = 'PRODUCTION_ON_CLICK_ACTION_BUTTON';
export const PRODUCTION_ON_CLICK_ACTION_BUTTON_PREPACK =
  'PRODUCTION_ON_CLICK_ACTION_BUTTON_PREPACK';
export const PRODUCTION_ON_CLICK_ACTION_BUTTON_END_OF_LANE_BUFFER_SPACE =
  'PRODUCTION_ON_CLICK_ACTION_BUTTON_END_OF_LANE_BUFFER_SPACE';

export const PRODUCTION_CLEAR_ERROR = 'PRODUCTION_CLEAR_ERROR';
export const PRODUCTION_CLEAR_SUB_ERROR_MESSAGES = 'PRODUCTION_CLEAR_SUB_ERROR_MESSAGES';
export const PRODUCTION_SET_VARIANT_SCANNED_PUBKEY_QUANTITY_IN_BOX =
  'PRODUCTION_SET_VARIANT_SCANNED_PUBKEY_QUANTITY_IN_BOX';
export const PRODUCTION_ON_SCAN_COMPLEMENTARY_ITEM = 'PRODUCTION_ON_SCAN_COMPLEMENTARY_ITEM';
export const PRODUCTION_ON_SCAN_VARIANT = 'PRODUCTION_ON_SCAN_VARIANT';
export const PRODUCTION_SET_BIN = 'PRODUCTION_SET_BIN';
export const PRODUCTION_SET_LOADING_TRUE = 'PRODUCTION_SET_LOADING_TRUE';
export const PRODUCTION_SET_LOADING_FALSE = 'PRODUCTION_SET_LOADING_FALSE';
export const PRODUCTION_ON_CLICK_ACTION_BUTTON_PACKING_STATION =
  'PRODUCTION_ON_CLICK_ACTION_BUTTON_PACKING_STATION';
export const PRODUCTION_SET_SUCCESS_MESSAGE = 'PRODUCTION_SET_SUCCESS_MESSAGE';
export const PRODUCTION_EMPTY_BIN_SUCCESS = 'PRODUCTION_EMPTY_BIN_SUCCESS';
export const PRODUCTION_ON_UPDATE_STATUS_SUCCESS = 'PRODUCTION_ON_UPDATE_STATUS_SUCCESS';
export const PRODUCTION_FETCH_PRODUCTION_STATIONS_SUCCESS =
  'PRODUCTION_FETCH_PRODUCTION_STATIONS_SUCCESS';
export const PRODUCTION_FETCH_PREPACK_LABS_SUCCESS = 'PRODUCTION_FETCH_PREPACK_LABS_SUCCESS';
export const PRODUCTION_SET_ERROR_MESSAGE = 'PRODUCTION_SET_ERROR_MESSAGE';
export const PRODUCTION_SET_SUB_ERROR_MESSAGES = 'PRODUCTION_SET_SUB_ERROR_MESSAGES';
export const PRODUCTION_SET_SHIPPING_LABEL_SCANNED = 'PRODUCTION_SET_SHIPPING_LABEL_SCANNED';
export const PRODUCTION_FETCH_PRODUCTION_STATIONS = 'PRODUCTION_FETCH_PRODUCTION_STATIONS';
export const PRODUCTION_FETCH_PREPACK_LABS = 'PRODUCTION_FETCH_PREPACK_LABS';
export const PRODUCTION_ON_CLICK_ACTION_BUTTON_PRODUCED =
  'PRODUCTION_ON_CLICK_ACTION_BUTTON_PRODUCED';
export const PRODUCTION_SET_FOCUS_QR_CODE_SCANNING_FIELD =
  'PRODUCTION_SET_FOCUS_QR_CODE_SCANNING_FIELD';
export const PRODUCTION_DONE_FOCUS_QR_CODE_SCANNING_FIELD =
  'PRODUCTION_DONE_FOCUS_QR_CODE_SCANNING_FIELD';
export const PRODUCTION_SET_FIX_MESSAGE = 'PRODUCTION_SET_FIX_MESSAGE';
export const PRODUCTION_CLEAR_FIX = 'PRODUCTION_CLEAR_FIX';

export const PRODUCTION_ON_SCAN_FORMULA_START = 'PRODUCTION_ON_SCAN_FORMULA_START';
export const PRODUCTION_ON_SCAN_FORMULA_SUCCESS = 'PRODUCTION_ON_SCAN_FORMULA_SUCCESS';
export const PRODUCTION_ON_SCAN_FORMULA_ERROR = 'PRODUCTION_ON_SCAN_FORMULA_ERROR';

export const PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_ERROR =
  'PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_ERROR';
export const PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_START =
  'PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_START';
export const PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_SUCCESS =
  'PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_SUCCESS';

export const PRODUCTION_PROD_ITEMS_UPDATE_ERROR = 'PRODUCTION_PROD_ITEMS_UPDATE_ERROR';
export const PRODUCTION_PROD_ITEMS_UPDATE_START = 'PRODUCTION_PROD_ITEMS_UPDATE_START';
export const PRODUCTION_PROD_ITEMS_UPDATE_SUCCESS = 'PRODUCTION_PROD_ITEMS_UPDATE_SUCCESS';
export const PRODUCTION_SET_NEXT_PREVIEW = 'PRODUCTION_SET_NEXT_PREVIEW';
export const PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_ERROR =
  'PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_ERROR';
export const PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_NOT_NEEDED =
  'PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_NOT_NEEDED';
export const PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_START =
  'PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_START';
export const PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_SUCCESS =
  'PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_SUCCESS';

export const PRODUCTION_QUALITY_TEST_CREATE_ERROR = 'PRODUCTION_QUALITY_TEST_CREATE_ERROR';
export const PRODUCTION_QUALITY_TEST_CREATE_START = 'PRODUCTION_QUALITY_TEST_CREATE_START';
export const PRODUCTION_QUALITY_TEST_CREATE_SUCCESS = 'PRODUCTION_QUALITY_TEST_CREATE_SUCCESS';
export const PRODUCTION_QUALITY_TEST_GET_ERROR = 'PRODUCTION_QUALITY_TEST_GET_ERROR';
export const PRODUCTION_QUALITY_TEST_GET_START = 'PRODUCTION_QUALITY_TEST_GET_START';
export const PRODUCTION_QUALITY_TEST_GET_SUCCESS = 'PRODUCTION_QUALITY_TEST_GET_SUCCESS';
export const PRODUCTION_QUALITY_TEST_STATUS_UPDATE_ERROR = 'PRODUCTION_QUALITY_TEST_PASS_ERROR';
export const PRODUCTION_QUALITY_TEST_STATUS_UPDATE_START = 'PRODUCTION_QUALITY_TEST_PASS_START';
export const PRODUCTION_QUALITY_TEST_STATUS_UPDATE_SUCCESS = 'PRODUCTION_QUALITY_TEST_PASS_SUCCESS';

export const PRODUCTION_DOWNLOAD_COLLATERALS_ERROR = 'PRODUCTION_DOWNLOAD_COLLATERALS_ERROR';
export const PRODUCTION_DOWNLOAD_COLLATERALS_START = 'PRODUCTION_DOWNLOAD_COLLATERALS_START';
export const PRODUCTION_DOWNLOAD_POLLING_COLLATERALS_SUCCESS =
  'PRODUCTION_DOWNLOAD_POLLING_COLLATERALS_SUCCESS';
export const PRODUCTION_DOWNLOAD_SYNC_COLLATERALS_SUCCESS =
  'PRODUCTION_DOWNLOAD_SYNC_COLLATERALS_SUCCESS';

export const PRODUCTION_PALLET_CREATE_ERROR = 'PRODUCTION_PALLET_CREATE_ERROR';
export const PRODUCTION_PALLET_CREATE_START = 'PRODUCTION_PALLET_CREATE_START';
export const PRODUCTION_PALLET_CREATE_SUCCESS = 'PRODUCTION_PALLET_CREATE_SUCCESS';
export const PRODUCTION_PALLET_FETCH_ERROR = 'PRODUCTION_PALLET_FETCH_ERROR';
export const PRODUCTION_PALLET_FETCH_START = 'PRODUCTION_PALLET_FETCH_START';
export const PRODUCTION_PALLET_FETCH_SUCCESS = 'PRODUCTION_PALLET_FETCH_SUCCESS';
export const PRODUCTION_PALLET_STATUS_UPDATE_ERROR = 'PRODUCTION_PALLET_STATUS_UPDATE_ERROR';
export const PRODUCTION_PALLET_STATUS_UPDATE_START = 'PRODUCTION_PALLET_STATUS_UPDATE_START';
export const PRODUCTION_PALLET_STATUS_UPDATE_SUCCESS = 'PRODUCTION_PALLET_STATUS_UPDATE_SUCCESS';

export const PRODUCTION_ON_SCAN_PALLET_START = 'PRODUCTION_ON_SCAN_PALLET_START';
export const PRODUCTION_ON_SCAN_PALLET_SUCCESS = 'PRODUCTION_ON_SCAN_PALLET_SUCCESS';
export const PRODUCTION_ON_SCAN_PALLET_ERROR = 'PRODUCTION_ON_SCAN_PALLET_ERROR';

export const PRODUCTION_ON_SCAN_LABEL_START = 'PRODUCTION_ON_SCAN_LABEL_START';
export const PRODUCTION_ON_SCAN_LABEL_SUCCESS = 'PRODUCTION_ON_SCAN_LABEL_SUCCESS';
export const PRODUCTION_ON_SCAN_LABEL_ERROR = 'PRODUCTION_ON_SCAN_LABEL_ERROR';

export const PRODUCTION_ON_DOWNLOAD_LABELS_START = 'PRODUCTION_ON_DOWNLOAD_LABELS_START';
export const PRODUCTION_ON_DOWNLOAD_LABELS_SUCCESS = 'PRODUCTION_ON_DOWNLOAD_LABELS_SUCCESS';
export const PRODUCTION_ON_DOWNLOAD_LABELS_ERROR = 'PRODUCTION_ON_DOWNLOAD_LABELS_ERROR';

export const PRODUCTION_ON_REMOVE_LABEL_START = 'PRODUCTION_ON_REMOVE_LABEL_START';
export const PRODUCTION_ON_REMOVE_LABEL_SUCCESS = 'PRODUCTION_ON_REMOVE_LABEL_SUCCESS';
export const PRODUCTION_ON_REMOVE_LABEL_ERROR = 'PRODUCTION_ON_REMOVE_LABEL_ERROR';

export const PRODUCTION_ON_CLEAR_LABELS_SUCCESS = 'PRODUCTION_ON_CLEAR_LABELS_SUCCESS';

export const PRODUCTION_ON_SCAN_PALLET_BOX_START = 'PRODUCTION_ON_SCAN_PALLET_BOX_START';
export const PRODUCTION_ON_SCAN_PALLET_BOX_SUCCESS = 'PRODUCTION_ON_SCAN_PALLET_BOX_SUCCESS';
export const PRODUCTION_ON_SCAN_PALLET_BOX_ERROR = 'PRODUCTION_ON_SCAN_PALLET_BOX_ERROR';

export const PRODUCTION_PALLETIZE_RESET_SUCCESS = 'PRODUCTION_PALLETIZE_RESET_SUCCESS';

export const PRODUCTION_REMOVE_BOX_PALLET_ERROR = 'PRODUCTION_REMOVE_BOX_PALLET_ERROR';
export const PRODUCTION_REMOVE_BOX_PALLET_START = 'PRODUCTION_REMOVE_BOX_PALLET_START';
export const PRODUCTION_REMOVE_BOX_PALLET_SUCCESS = 'PRODUCTION_REMOVE_BOX_PALLET_SUCCESS';

export const PRODUCTION_DISPATCH_SELECT_LANE = 'PRODUCTION_DISPATCH_SELECT_LANE';
export const PRODUCTION_DISPATCH_DESELECT_LANE = 'PRODUCTION_DISPATCH_DESELECT_LANE';

export const PRODUCTION_DISPATCH_SET_SELECTED_LANES = 'PRODUCTION_DISPATCH_SET_SELECTED_LANES';

export const PRODUCTION_DOWNLOAD_QR_BULK_ERROR = 'PRODUCTION_DOWNLOAD_QR_BULK_ERROR';
export const PRODUCTION_DOWNLOAD_QR_BULK_START = 'PRODUCTION_DOWNLOAD_QR_BULK_START';
export const PRODUCTION_DOWNLOAD_QR_BULK_SUCCESS = 'PRODUCTION_DOWNLOAD_QR_BULK_SUCCESS';

export const PRODUCTION_PALLET_QUALITY_TEST_GET_ERROR = 'PRODUCTION_PALLET_QUALITY_TEST_GET_ERROR';
export const PRODUCTION_PALLET_QUALITY_TEST_GET_START = 'PRODUCTION_PALLET_QUALITY_TEST_GET_START';
export const PRODUCTION_PALLET_QUALITY_TEST_GET_SUCCESS =
  'PRODUCTION_PALLET_QUALITY_TEST_GET_SUCCESS';
