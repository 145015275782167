import { isError } from 'types/predicates';

import createApiClient from 'services/CreateApiClient';

import logSentryError from 'utils/sentry';

import { savePagination } from 'dux/pagination/slice';

import type { FetchJson } from './types';
import {
  getBody,
  getMethod,
  getRelativeUrl,
  isAuthErrorMessage,
  serializeError,
  stringifyError,
} from './utils';

const fetchJson: FetchJson =
  ({ baseUrl }) =>
  async (args, api) => {
    const method = getMethod(args);
    const body = getBody(args);
    const baseArg = `${baseUrl}${getRelativeUrl(args)}`;
    const apiClientArgs = method === 'GET' ? [baseArg] : [baseArg, body];

    const callApi = createApiClient(api.dispatch, method);

    try {
      const response = await callApi(...apiClientArgs);
      const pages = response?.pages;

      if (pages) {
        api.dispatch(
          savePagination({
            pages,
            callType: api.type,
            endpoint: api.endpoint,
            arg: getRelativeUrl(args),
          })
        );
      }

      const payload = await response.json();
      return { data: payload };
    } catch (error) {
      if (!isError(error)) return { error: null }; // it's always an error so shouldn't get here

      if (!isAuthErrorMessage(stringifyError(error))) {
        logSentryError(`[JSON base query] ${api.endpoint}`, error); // no need to log to Sentry if it's an auth error
      }

      return { error: serializeError(error) };
    }
  };

export default fetchJson;
