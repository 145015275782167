import HTTPError from 'Errors/HTTPError';
import { isHTTPError } from 'types/predicates';

import type { ExportsError } from './types';

type MessageUpdater = (arg: string) => string;
type UpdateError = (messageUpdater: MessageUpdater) => (oldError: ExportsError) => ExportsError;
export const updateErrorMessage: UpdateError = messageUpdater => oldError => {
  if (isHTTPError(oldError)) {
    return new HTTPError(
      oldError.code,
      oldError.body,
      messageUpdater(
        `${oldError.code ? `<Error code: ${oldError.code}> ` : ''}${oldError.body?.message}`
      )
    );
  }
  if (oldError instanceof SyntaxError) {
    return new SyntaxError(messageUpdater(oldError.message));
  }

  return new Error(messageUpdater(oldError.message)); // fallback
};

export const stringifyError = (error: ExportsError): string => {
  if (isHTTPError(error)) return error.body?.message ?? '';

  return error.message ?? error.toString();
};
