import type { TypedUseSelectorHook } from 'react-redux';

import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from '../store';

// same as useDispatch but already typed — you won't have to import types and do useDispatch<MyType> every time
export const useOmsDispatch: () => AppDispatch = useDispatch;

// same as useSelector but already typed — you won't have to import types and do useSelector<MyType> every time
export const useOmsSelector: TypedUseSelectorHook<RootState> = useSelector;
