import reduce from 'lodash/fp/reduce';

import type { IApiResponse } from '../services/ApiResponse.types';

async function getJson<T extends unknown[]>(promisesArr: unknown[]): Promise<T> {
  return reduce(
    async (accPromise: T, currPromise: IApiResponse) => {
      const json = await currPromise.json();
      const acc = await accPromise; // accumulator is a Promise during the async iteration
      return [...acc, json];
    },
    [],
    promisesArr
  );
}

export default getJson;
