import every from 'lodash/fp/every';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import replace from 'lodash/fp/replace';
import some from 'lodash/fp/some';

const minisSlugs = ['cleanser_mini', 'moisturizer_mini', 'serum_mini'];

const minisPubkeys = [
  '323c9fb1-0f41-4422-bbd9-a7a8c9129770',
  '2488ce80-7a07-42c0-a7d6-a95c42ef20a0',
  'b7e6020f-6937-4d74-801e-a5e29b25465d',
];

export const isMiniProduct = product => includes(product, minisPubkeys);

export const groupedSkincareMinis = products => {
  const productTypes = map(product => product?.product?.type, products);

  const hasMiniSlug = some(type => includes(type, minisSlugs), productTypes);

  if (hasMiniSlug) {
    return every(miniSlug => includes(miniSlug, productTypes), minisSlugs);
  }

  return true;
};

// to compare skincare minis formulas to full sizes even if different products
export const harmonizeForSkincareMinis = product => replace('_mini', '', product);
