import React, { useEffect, useMemo, useState } from 'react';

import Autosuggest from 'react-autosuggest';
import { useNavigate } from 'react-router-dom';

import debounce from 'lodash/debounce';
import every from 'lodash/fp/every';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import SearchIcon from '@mui/icons-material/Search';
import type { Notification, Nullable } from 'types/common';
import { isError } from 'types/predicates';
import { useDebounce } from 'use-debounce';

import useApiClient from 'services/useApiClient';

import {
  getSectionSuggestions,
  renderSectionTitle,
  renderSuggestion,
  renderSuggestionsContainer,
  SearchBar,
  SearchIconContainer,
} from './AppSearch.components';
import SnackbarAlert from './SnackbarAlert';

type Controller = InstanceType<typeof window.AbortController>;

type Suggestion = {
  title: string;
  suggestions: unknown[];
};

const AppSearch = () => {
  const APIClient = useApiClient();
  const [searchValue, setSearchValue] = useState('');
  const [searchText] = useDebounce(searchValue, 300);
  const [notification, setNotification] = useState<Notification>({
    message: null,
    severity: 'info',
  });
  const [controller, setController] = useState<Nullable<Controller>>(null);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchText && controller?.signal) {
      const regExp = /^http/i;

      if (regExp.test(searchText)) {
        const orderItemRegex =
          /^https:\/\/prose.com\/([fp])\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/gim;
        const re = orderItemRegex.exec(searchText.toLowerCase());
        if (re) {
          (async () => {
            try {
              const prefix = re[1];
              const pubkey = re[2];
              const resp = await APIClient.get(
                `/v1/backoffice/${prefix === 'f' ? 'formulas' : 'proditems'}/${pubkey}/`
              );
              const formula = await resp.json();
              setSearchValue('');
              navigate(`/orders/${formula?.order?.pubkey}/formulas`);
            } catch (err) {
              setNotification({ message: 'Item not found', severity: 'error' });
              setSearchValue('');
            }
          })();
        }
      } else {
        (async () => {
          let results;
          try {
            // Pass the signal as a 4th value to the API get function for aborting purpose
            const orderSearch = APIClient.get(
              '/v1/backoffice/orders/',
              { q: searchText },
              null,
              controller.signal
            );
            const customerSearch = APIClient.get(
              '/v1/backoffice/customers/',
              { q: searchText },
              null,
              controller.signal
            );
            const salonSearch = APIClient.get(
              '/v1/backoffice/salons/',
              { q: searchText },
              null,
              controller.signal
            );
            const responses = await Promise.all([customerSearch, orderSearch, salonSearch]);
            results = await Promise.all(map(response => response.json(), responses));
            if (every(isEmpty, results)) {
              setNotification({ message: 'No results found', severity: 'error' });
            }
          } catch (err) {
            if (!isError(err)) return;

            if (err.name === 'AbortError') {
              return;
            }
            setNotification({ message: 'Something went wrong', severity: 'error' });
            throw err;
          }

          setSuggestions([
            { title: 'Customers', suggestions: get([0], results) },
            { title: 'Orders', suggestions: get([1], results) },
            { title: 'Salons', suggestions: get([2], results) },
          ]);
        })();
      }
    }
  }, [controller, navigate, searchText, APIClient]);

  const onSuggestionsFetchRequested = useMemo(
    () =>
      debounce(() => {
        // if there is a controller setup, it means there might be an ongoing request, try to cancel it first
        if (controller) {
          controller.abort();
        }
        // then, create a new abort controller and only after (on useEffect), try to fetch with the controller signal
        setController(new window.AbortController());
      }, 100),
    [controller]
  );

  return (
    <SearchBar data-testid="search-bar">
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <Autosuggest
        focusInputOnSuggestionClick={false}
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={() => searchValue}
        inputProps={{
          onChange: event => {
            setSearchValue(event.target.value);
          },
          type: 'search',
          value: searchValue,
        }}
        multiSection
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderSectionTitle={renderSectionTitle}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        theme={{
          suggestionsContainerOpen: {
            left: 0,
            position: 'absolute',
            right: 0,
            zIndex: 1,
          },
          suggestion: {
            display: 'block',
          },
          suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
          },
        }}
      />
      <SnackbarAlert notification={notification} setNotification={setNotification} />
    </SearchBar>
  );
};

export default AppSearch;
