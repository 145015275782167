import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';

import initApiClient from 'services/ApiClient';

import fetchAllPages from 'utils/fetchAllPages';
import logSentryError from 'utils/sentry';

import type {
  ColRowIndex,
  Complex,
  Complexes,
  HighlightedCol,
  HighlightedRow,
  LabInfosLanes,
  LabInfosState,
  Skeleton,
  Skeletons,
} from './types';

export const fetchSkeletons = createAsyncThunk<Skeletons, void>(
  'lab-infos/fetchSkeletons',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const result = await fetchAllPages<Skeleton>(`/v1/backoffice/skeletons`, APIClient);
      return result;
    } catch (err) {
      logSentryError('[Skeletons LabInfos] fetch skeletons', err);
      throw err;
    }
  }
);

export const fetchComplexes = createAsyncThunk<Complexes, void>(
  'lab-infos/fetchComplexes',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const result = await fetchAllPages<Complex>(`/v1/backoffice/ingredients`, APIClient);
      return result;
    } catch (err) {
      logSentryError('[Skeletons LabInfos] fetch complexes', err);
      throw err;
    }
  }
);

const initialState: LabInfosState = {
  skeletons: [],
  complexes: [],
  status: 'idle',
  error: null,
  hoveredRow: null,
  hoveredCol: null,
  selectedLanes: [],
  selectedSkeletons: [],
  selectedComplexes: [],
  highlightedRows: [],
  highlightedCols: [],
};

const labInfosSlice = createSlice({
  name: 'lab-infos',
  initialState,
  reducers: {
    setHighlight(draftState, action: PayloadAction<ColRowIndex>) {
      const { rowIndex, colIndex } = action.payload;
      draftState.hoveredRow = rowIndex;
      draftState.hoveredCol = colIndex;
    },
    resetHighlight(draftState) {
      draftState.hoveredRow = null;
      draftState.hoveredCol = null;
    },
    setSelectedSkeletons(draftState, action: PayloadAction<Skeletons>) {
      draftState.selectedSkeletons = action.payload;
    },
    setSelectedComplexes(draftState, action: PayloadAction<Complexes>) {
      draftState.selectedComplexes = action.payload;
    },
    setSelectedLanes(draftState, action: PayloadAction<LabInfosLanes>) {
      draftState.selectedLanes = action.payload;
    },
    addManuallyHighlightedRow(draftState, action: PayloadAction<HighlightedRow>) {
      draftState.highlightedRows.push(action.payload);
    },
    removeManuallyHighlightedRow(draftState, action: PayloadAction<HighlightedRow>) {
      draftState.highlightedRows = filter(
        item => item !== action.payload,
        draftState.highlightedRows
      );
    },
    addManuallyHighlightedCol(draftState, action: PayloadAction<HighlightedCol>) {
      draftState.highlightedCols.push(action.payload);
    },
    removeManuallyHighlightedCol(draftState, action: PayloadAction<HighlightedCol>) {
      draftState.highlightedCols = filter(
        item => item !== action.payload,
        draftState.highlightedCols
      );
    },
    resetManualHighlight(draftState) {
      draftState.highlightedRows = [];
      draftState.highlightedCols = [];
    },
  },
  extraReducers: builder => {
    builder
      // fetchSkeletons
      .addCase(fetchSkeletons.pending, draftState => {
        draftState.status = 'pending';
        draftState.skeletons = [];
        draftState.error = null;
      })
      .addCase(fetchSkeletons.fulfilled, (draftState, action) => {
        const skeletons = action.payload;

        draftState.status = 'fulfilled';
        draftState.skeletons = skeletons;
      })
      .addCase(fetchSkeletons.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // fetch complexes
      .addCase(fetchComplexes.pending, draftState => {
        draftState.status = 'pending';
        draftState.complexes = [];
        draftState.error = null;
      })
      .addCase(fetchComplexes.fulfilled, (draftState, action) => {
        const complexes = action.payload;

        draftState.status = 'fulfilled';
        draftState.complexes = complexes;
      })
      .addCase(fetchComplexes.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer, actions } = labInfosSlice;

export const {
  setHighlight,
  resetHighlight,
  setSelectedSkeletons,
  setSelectedComplexes,
  setSelectedLanes,
  addManuallyHighlightedRow,
  removeManuallyHighlightedRow,
  addManuallyHighlightedCol,
  removeManuallyHighlightedCol,
  resetManualHighlight,
} = actions;

export default reducer;
