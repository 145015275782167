import React, { type ErrorInfo } from 'react';

import logSentryError from 'utils/sentry';

import ErrorComponent from './ErrorComponent';

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  return <ErrorComponent error={error ?? null} resetErrorBoundary={resetErrorBoundary ?? null} />;
};

export const logError = (error: Error, info: ErrorInfo) => {
  logSentryError(() => `[Error Boundary] catch error: ${error.message}, ${info}`, error);
};

export default ErrorFallback;
