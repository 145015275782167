import { blue, green, orange, red, yellow } from '@mui/material/colors';
import type { Theme } from '@mui/material/styles/createTheme';

export const customizedMui = {
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffbb93',
      main: '#ff8a65',
      dark: '#c75b39',
      contrastText: '#fff',
    },
    deprecated: {
      dark: '#9f9f9f',
      light: '#f4eeee',
    },
    mode: 'light',
  },
  status: {
    ok: blue[700],
    partial: yellow[700],
    success: green[700],
    warning: orange[700],
    error: red[700],
  },
  typography: {
    useNextVariants: true,
  },
};

type CustomizedMui = Theme & typeof customizedMui;

// Prose colors from:
// https://docs.google.com/presentation/d/1_oK-PSmJKNaLdUKjEkSVWygjU-YMr76IvqvEs_paD0E/edit?usp=sharing
// https://zpl.io/Pqzvvv6
export const proseTheme = {
  fonts: {
    main: "'Simplon Norm', 'Helvetica Neue', Helvetica, sans-serif",
    google: "'Google Sans', 'Helvetica Neue', Helvetica, sans-serif",
  },
  colors: {
    white: '#ffffff',
    primary: {
      creme: {
        dark: '#E6DBD2',
        main: '#f6f0eb',
        light: '#FBF6F2',
      },
      lilas: {
        darker: '#92929e',
        dark: '#bbbccb',
        main: '#D0D1E1',
        light: '#d9dae7',
        lighter: '#e8e8f0',
      },
      ocre: {
        darker: '#85633c',
        dark: '#ab7f4d',
        main: '#be8d56',
        light: '#cba478',
        lighter: '#dfc6ab',
      },
      vert: {
        darker: '#7d867f',
        dark: '#a1ada3',
        main: '#b3c0b5',
        light: '#c2cdc4',
        lighter: '#d9e0da',
      },
      foret: {
        darker: '#313520',
        dark: '#4c4e3e',
        main: '#545745',
        light: '#76796a',
        lighter: '#aaaba2',
      },
      noir: {
        darker: '#1c2424',
        dark: '#242e2e',
        main: '#283333',
        light: '#535c5c',
        lighter: '#949999',
      },
    },
    secondary: {
      sorbet: {
        darker: '#b3836d',
        dark: '#e6a88c',
        main: '#ffbb9b',
        light: '#ffc9af',
        lighter: '#ffddcd',
      },
      bleu: {
        darker: '#7e8b94',
        dark: '#a2b3be',
        main: '#b4c7d3',
        light: '#c3d2dc',
        lighter: '#dae3e9',
      },
      berry: {
        darker: '#2b0812',
        dark: '#370b17',
        main: '#3D0C1A',
        light: '#643d48',
        lighter: '#9e868d',
      },
    },
    special: {
      supplements: {
        amber: '#ffd378',
        cyanBlue: '#dbedff',
      },
    },
    text: {
      darker: '#283333',
      dark: '#6c6c6c',
      grey: '#a6a6a6',
      light: '#eaeaea',
      white: '#ffffff',
    },
    states: {
      green: '#2e844a',
      red: '#af2800',
    },
  },
};

type ProseTheme = { prose: typeof proseTheme };

export type OmsTheme = CustomizedMui & ProseTheme;
