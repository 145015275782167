import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// Example of parent component state for SnackbarAlert:
// const [notification, setNotification] = useState({
//   message: null,
//   severity: 'info',
// });

const SnackbarAlert = ({ notification, setNotification }) => {
  const [localOpen, setLocalOpen] = useState(Boolean(notification?.message));
  if (!notification?.message) return null;
  return (
    <Snackbar
      open={localOpen || Boolean(notification?.message ?? false)}
      autoHideDuration={3000}
      onClose={() => {
        setLocalOpen(false);
        setNotification({ message: null, severity: null });
      }}
      message={notification?.message ?? ''}
      data-testid="notification-snackbar"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      data-sentry-unmask
    >
      <Alert
        severity={notification?.severity ?? 'info'}
        onClose={() => {
          setLocalOpen(false);
          setNotification({ message: null, severity: null });
        }}
      >
        {notification?.message ?? ''}
      </Alert>
    </Snackbar>
  );
};

SnackbarAlert.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.string,
  }),
  setNotification: PropTypes.func.isRequired,
};

SnackbarAlert.defaultProps = {
  notification: {
    message: null,
    severity: 'info',
  },
};

export default SnackbarAlert;
