import * as Sentry from '@sentry/react';

export const unsetLanes = state => {
  return {
    ...state,
    production: {
      ...state.production,
      lanes: null,
    },
  };
};

const createSentryReduxEnhancer = () =>
  Sentry.createReduxEnhancer({
    stateTransformer: unsetLanes,
  });

export default createSentryReduxEnhancer;
