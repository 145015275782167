import assign from 'lodash/fp/assign';
import find from 'lodash/fp/find';
import indexOf from 'lodash/fp/indexOf';
import isEmpty from 'lodash/fp/isEmpty';
import reduce from 'lodash/fp/reduce';

const addDuplicateVariantToScan = variants =>
  reduce(
    (acc, value) => {
      if (!isEmpty(acc)) {
        const findSameItem = find(
          { variant: { product: { type: value.variant.product.type }, type: value.variant.type } },
          acc
        );
        if (findSameItem) {
          acc[indexOf(findSameItem, acc)] = assign(findSameItem, {
            quantity: findSameItem.quantity + value.quantity,
            quantity_in_box: findSameItem.quantity + value.quantity,
          });
          return acc;
        }
      }
      return [...acc, value];
    },
    [],
    variants
  );

export default addDuplicateVariantToScan;
