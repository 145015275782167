import type { APIClient } from 'services/ApiClient.types';

import type { AuthToken } from 'dux/auth/types';

export const getSignInToken = authLink => {
  const token: string = decodeURIComponent(authLink).split('token=')[1];
  return token;
};

export const verifySigninToken = async (token: string, APIClient: APIClient) => {
  const response = await APIClient.post('/v1/auth/signin/verify/', { token });
  const payload = await response.json();
  const auth: AuthToken = {
    accessToken: payload.access,
    refreshToken: payload.refresh,
  };
  return auth;
};
