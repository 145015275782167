import get from 'lodash/fp/get';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

type MetadataSlice = RootState['metadata'];

const getMetadataState: OmsSelector<MetadataSlice> = state => state.metadata;

const getSectionTitle: OmsSelector<MetadataSlice['sectionTitle']> = createSelector(
  getMetadataState,
  get('sectionTitle')
);

export default getSectionTitle;
