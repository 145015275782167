import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { CouponsIssueReasons, CouponsState } from './types';

export const fetchCouponsIssueReasons = createAsyncThunk<CouponsIssueReasons>(
  'coupons/fetchCouponsIssueReasons',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/coupon_issue_reasons/`);
      const couponsIssueReasons: CouponsIssueReasons = await response.json();
      return couponsIssueReasons;
    } catch (err) {
      logSentryError('[fetchCouponsIssueReasons] fetch coupons issue reasons', err);
      throw err;
    }
  }
);

const initialState: CouponsState = {
  status: 'idle',
  couponsIssueReasons: [],
  error: null,
};

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCouponsIssueReasons.pending, draftState => {
        draftState.status = 'pending';
        draftState.couponsIssueReasons = [];
        draftState.error = null;
      })
      .addCase(fetchCouponsIssueReasons.fulfilled, (draftState, action) => {
        const couponsIssueReasons = action.payload;

        draftState.status = 'fulfilled';
        draftState.couponsIssueReasons = couponsIssueReasons;
      })
      .addCase(fetchCouponsIssueReasons.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = couponsSlice;

export default reducer;
