export default {
  // Hair textures `hair_texture`
  smooth: 'Type 1: Straight',
  wavy: 'Type 2: Wavy or Loose Curl (deprecated)',
  type_2a: 'Type 2A: Soft Waves',
  type_2b: 'Type 2B: Wavy',
  type_2c: 'Type 2C: Deep Waves or Loose Curls',
  curly: 'Type 3 (deprecated)',
  type_3a: 'Type 3A: Curly',
  type_3b: 'Type 3B: Very Curly',
  type_3c: 'Type 3C: Tight Curls',
  very_curly: 'Type 4A: Coily',
  frizzy: 'Type 4B: Zig-Zag Coils',
  zigzag: 'Type 4C: Zig-Zag Coily',

  // Legacy indicators
  extensions_type: 'Extensions type (deprecated)',
  partial_or_tips: 'Parital or Tips (deprecated)',
};
