import filter from 'lodash/fp/filter';
import reduce from 'lodash/fp/reduce';

export const recommendationMap = {
  preselect: 'Your custom recommendations',
  main: 'Your custom recommendations',
  upsale: 'Enhance your Routine',
  featured: 'New & Noteworthy',
  optional: 'Explore other custom products (see more)',
  inadvisable: 'Inadvisable (will also show up in the see more section)',
};

export const groupedByRecommendationSection = catalog => {
  const sections = ['preselect', 'main', 'upsale', 'featured', 'optional', 'inadvisable'];
  return reduce(
    (accumulator, section) => {
      accumulator[section] = filter({ recommendation: { section } }, catalog);
      return accumulator;
    },
    {},
    sections
  );
};
