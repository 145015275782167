import * as Spotlight from '@spotlightjs/spotlight';

import stringToBoolOrValue from './envParsing';
import getEnv from './getEnv';
import initSentry from './initSentry';

const addSentry = () => {
  if (getEnv('MODE') !== 'test') {
    initSentry();
  }
  if (
    getEnv('REACT_APP_ENV') === 'development' &&
    stringToBoolOrValue(getEnv('REACT_APP_SENTRY_SPOTLIGHT'))
  ) {
    Spotlight.init();
  }
};

export default addSentry;
