import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { signout } from 'services/Auth';

import * as AuthActions from 'dux/auth/slice';

import { useOmsDispatch } from '../hooks/rtk';

const SignoutScene = () => {
  const navigate = useNavigate();
  const dispatch = useOmsDispatch();

  useEffect(() => {
    dispatch(AuthActions.signout());
    signout();
    navigate('/', { replace: true });
  }, [dispatch, navigate]);

  return null;
};

export default SignoutScene;
