const pumpsMap = {
  dropper_serum: 'Serum Dropper',
  pump_cleanser: 'Cleanser Pump',
  leavein_conditioner_rich_pkgr: 'LIC Pump',
  pump_150_ml_pkgr: '150 Pump',
  shampoo_conditioner_pkgr: 'Shampoo Conditioner Pump',
  // three lines below can be deleted once API MR #4430 is merged
  leavein_conditioner_rich: 'LIC Pump',
  pump_150_ml: '150 Pump',
  shampoo_conditioner: 'Shampoo Conditioner Pump',
};

export default pumpsMap;
