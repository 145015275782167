import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import replace from 'lodash/fp/replace';

import { capitalize, humanize } from 'helpers';

export const getItemType = item => {
  const itemVariantProductType = get('variant.product.type')(item);
  const itemObjectSkeleton = get('item_object.skeleton')(item);
  const isBulky = get('variant.is_bulky')(item);
  if (itemObjectSkeleton) {
    return itemVariantProductType === 'leavein' ? 'HAIR OIL' : capitalize(itemVariantProductType);
  }
  if (isBulky || !item?.type) {
    return itemVariantProductType;
  }
  return item?.type;
};

export const getItemName = item => {
  const itemVariantProductType = get('variant.product.type')(item);
  const itemObjectSkeleton = get('item_object.skeleton')(item);
  const itemVariantSlug = get('variant.slug')(item);
  const itemIsBulky = get('variant.is_bulky')(item);
  if (itemObjectSkeleton) {
    if (
      includes(itemVariantProductType, ['supplement_core', 'supplement_booster']) &&
      includes('pouch', itemVariantSlug)
    ) {
      if (itemVariantProductType === 'supplement_core') {
        return replace(itemObjectSkeleton?.name.substring(3), 'Refills', itemObjectSkeleton?.name);
      }
      return 'Refills';
    }

    if (itemObjectSkeleton.name === 'scalp massager gwp') {
      return humanize(`${item?.variant?.slug}_${item?.variant?.version}`);
    }

    if (includes('Mini', itemObjectSkeleton.name)) {
      return humanize(`Mini ${itemObjectSkeleton.slug}`);
    }

    return `${itemObjectSkeleton.name} ${
      itemObjectSkeleton.type === 'postcard' && includes('membership', itemObjectSkeleton.name)
        ? humanize(`${item?.variant?.slug}_${item?.variant?.version}`)
        : ''
    }`;
  }

  if (itemVariantSlug === 'membership_postcard') {
    return `${itemVariantSlug} ${humanize(`${item?.variant?.slug}_${item?.variant?.version}`)}`;
  }

  if (itemIsBulky || !item?.name) {
    return humanize(itemVariantSlug);
  }
  return item?.name;
};
