import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import overSome from 'lodash/fp/overSome';
import some from 'lodash/fp/some';

import { capitalize, humanize } from 'helpers';
import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import orderFreeReasons from 'Apps/Customers/constants/reasonsFreeOrder';

import { getItemName } from 'utils/itemsHelper';

import type { DispatchableItem } from '../orderDispatch/types';
import type { ShippingAddress } from '../subscriptions/types';
import type {
  Coupon,
  Customer,
  FormattedNonShippableItemsDetails,
  Formulaset,
  FreeOrderReasons,
  GiftItem,
  Order,
  Refund,
  Survey,
  Tag,
} from './types';

type OrdersSlice = RootState['orders'];

const getOrdersState: OmsSelector<OrdersSlice> = state => state.orders;

export const getOrders: OmsSelector<OrdersSlice['orders']> = createSelector(
  getOrdersState,
  get('orders')
);
export const getFormula: OmsSelector<OrdersSlice['formula']> = createSelector(
  getOrdersState,
  get('formula')
);

export const getFetchStatus: OmsSelector<OrdersSlice['status']> = createSelector(
  getOrdersState,
  get('status')
);
export const getPages: OmsSelector<OrdersSlice['pages']> = createSelector(
  getOrdersState,
  get('pages')
);
export const getError: OmsSelector<OrdersSlice['error']> = createSelector(
  getOrdersState,
  get('error')
);
export const getCurrentOrder: OmsSelector<OrdersSlice['currentOrder']> = createSelector(
  getOrdersState,
  get('currentOrder')
);
export const getDefectBox: OmsSelector<OrdersSlice['defectBox']> = createSelector(
  getOrdersState,
  get('defectBox')
);

export const getRefunds: OmsSelector<Order['refunds']> = createSelector(
  getCurrentOrder,
  get('refunds')
);

export const getSerialNo: OmsSelector<Order['serial_no']> = createSelector(
  getCurrentOrder,
  get('serial_no')
);
export const getPaymentMode: OmsSelector<Order['payment_mode']> = createSelector(
  getCurrentOrder,
  get('payment_mode')
);
export const getPaymentName: OmsSelector<'Paypal' | 'Stripe'> = createSelector(
  getPaymentMode,
  paymentMode => (paymentMode === 'braintree' ? 'Paypal' : 'Stripe')
);
export const getPaymentUrl: OmsSelector<Order['payment_url']> = createSelector(
  getCurrentOrder,
  get('payment_url')
);
export const getTotalShipping: OmsSelector<Order['total_shipping']> = createSelector(
  getCurrentOrder,
  get('total_shipping')
);
export const getTotalAmount: OmsSelector<Order['total_amount']> = createSelector(
  getCurrentOrder,
  get('total_amount')
);
export const getTotalPrice: OmsSelector<Order['total_price']> = createSelector(
  getCurrentOrder,
  get('total_price')
);
export const getTotalPriceWithTaxes: OmsSelector<Order['total_price_with_taxes']> = createSelector(
  getCurrentOrder,
  get('total_price_with_taxes')
);
export const getTotalTaxes: OmsSelector<Order['total_taxes']> = createSelector(
  getCurrentOrder,
  get('total_taxes')
);

export const getPaymentFromCustomer: OmsSelector<Order['payment_from_customer']> = createSelector(
  getCurrentOrder,
  get('payment_from_customer')
);

export const getPaymentFromGiftCard: OmsSelector<Order['payment_from_gift_card']> = createSelector(
  getCurrentOrder,
  get('payment_from_gift_card')
);

export const getCurrency: OmsSelector<Order['currency']> = createSelector(
  getCurrentOrder,
  get('currency')
);
export const getTotalCredit: OmsSelector<Order['total_credit']> = createSelector(
  getCurrentOrder,
  get('total_credit')
);
export const getTaxPercent: OmsSelector<Order['tax_rate']> = createSelector(
  getCurrentOrder,
  order => get('tax_rate', order) * 100 // Not a per-cent-age but a per-one-age so need to multiply by 100
);

export const getOrderItems: OmsSelector<Order['items']> = createSelector(
  getCurrentOrder,
  get('items')
);

export const getOpenTextFeedback: OmsSelector<any> = createSelector(
  getCurrentOrder,
  get('open_text_feedback')
);

export const getOrderFormulaset: OmsSelector<Order['formulaset']> = createSelector(
  getCurrentOrder,
  get('formulaset')
);

export const getOrderFormulasetSurvey: OmsSelector<Formulaset['survey']> = createSelector(
  getOrderFormulaset,
  get('survey')
);

export const getOrderFormulasetSurveyPubkey: OmsSelector<Survey['pubkey']> = createSelector(
  getOrderFormulasetSurvey,
  get('pubkey')
);

export const isRefundableByProduct: OmsSelector<Order['allow_refund_by_product']> = createSelector(
  getCurrentOrder,
  get('allow_refund_by_product')
); // Boolean
export const getCouponCustomers: OmsSelector<Order['coupon_customers']> = createSelector(
  getCurrentOrder,
  get('coupon_customers')
);
export const getNonRefundableItemsFromCoupons: OmsSelector<Coupon['products']> = createSelector(
  getCouponCustomers,
  flow(filter({ coupon: { coupon_type: 'free_products' } }), map(get('coupon.products')), flatten)
);

export const getShippingAddress: OmsSelector<Order['shipping_address']> = createSelector(
  getCurrentOrder,
  get('shipping_address')
);
export const getShippingCountry: OmsSelector<ShippingAddress['country']> = createSelector(
  getShippingAddress,
  get('country')
);

export const getIsForeign: OmsSelector<boolean> = createSelector(
  getShippingCountry,
  country => country !== 'US'
);
export const getTags: OmsSelector<Order['tags']> = createSelector(getCurrentOrder, get('tags')); // [urban_cowboy, has_defect]
export const getCreatedAt: OmsSelector<Order['created_at']> = createSelector(
  getCurrentOrder,
  get('created_at')
);
export const getCustomer: OmsSelector<Order['customer']> = createSelector(
  getCurrentOrder,
  get('customer')
);
export const getCustomerFirstName: OmsSelector<Customer['first_name']> = createSelector(
  getCustomer,
  get('first_name')
);
export const getCustomerLastName: OmsSelector<Customer['last_name']> = createSelector(
  getCustomer,
  get('last_name')
);
export const getCustomerPubkey: OmsSelector<Customer['pubkey']> = createSelector(
  getCustomer,
  get('pubkey')
);
export const getCustomerUserTags: OmsSelector<Order['tags']> = createSelector(
  getCustomer,
  get('tags')
);
export const getCustomerUserTagSlugs: OmsSelector<Tag['slug'][]> = createSelector(
  getCustomerUserTags,
  map(get('slug'))
);
export const getCustomerIsVip: OmsSelector<boolean> = createSelector(
  getCustomerUserTagSlugs,
  includes('vip')
);
export const getCustomerIsInfluencer: OmsSelector<boolean> = createSelector(
  getCustomerUserTagSlugs,
  includes('influencer')
);

export const getIsCustomerFirstOrder: OmsSelector<Order['customer_first_order']> = createSelector(
  getCurrentOrder,
  get('customer_first_order')
);
export const getFormulaFeedback: OmsSelector<Order['formula_feedback']> = createSelector(
  getCurrentOrder,
  get('formula_feedback')
);
export const getFormulaFeedbacksGeneratedFrom: OmsSelector<
  Order['formula_feedbacks_generated_from']
> = createSelector(getCurrentOrder, get('formula_feedbacks_generated_from'));
export const getGiftReason: OmsSelector<Order['gift_reason']> = createSelector(
  getCurrentOrder,
  get('gift_reason')
);
export const getGifter: OmsSelector<Order['gifter']> = createSelector(
  getCurrentOrder,
  get('gifter')
);
export const getGifterFullName: OmsSelector<Order['gifter_fullname']> = createSelector(
  getCurrentOrder,
  get('gifter_fullname')
);
export const getIsFromGift: OmsSelector<Order['is_from_gift']> = createSelector(
  getCurrentOrder,
  get('is_from_gift')
);
export const getIsGift: OmsSelector<Order['is_gift']> = createSelector(
  getCurrentOrder,
  get('is_gift')
);
export const getIsGiftBrush: OmsSelector<Order['is_gift_brush']> = createSelector(
  getCurrentOrder,
  get('is_gift_brush')
);
export const getIsSubscription: OmsSelector<Order['is_subscription']> = createSelector(
  getCurrentOrder,
  get('is_subscription')
);
export const getPriceExperimentVariant: OmsSelector<Order['price_experiment_variant']> =
  createSelector(getCurrentOrder, get('price_experiment_variant'));
export const getStatus: OmsSelector<Order['status']> = createSelector(
  getCurrentOrder,
  get('status')
);
export const getStatuses: OmsSelector<Order['statuses']> = createSelector(
  getCurrentOrder,
  get('statuses')
);
export const getSubscriptionPubkey: OmsSelector<Order['subscription_pubkey']> = createSelector(
  getCurrentOrder,
  get('subscription_pubkey')
);
export const getFreeOrderReason: OmsSelector<Order['free_order_reason']> = createSelector(
  getCurrentOrder,
  get('free_order_reason')
);
export const getFreeOrderReasonLabel: OmsSelector<FreeOrderReasons> = createSelector(
  getFreeOrderReason,
  reason => get('label', find({ value: reason }, orderFreeReasons))
);

export const getNonShippableItems: OmsSelector<Order['items']> = createSelector(
  getOrderItems,
  filter({ variant: { is_shippable: false } })
);

export const getRefundedItems: OmsSelector<Refund['refund_items_details']> = createSelector(
  getRefunds,
  flow(map('refund_items_details'), flatten)
);

const getCanceledOrderItemsPubkeys: OmsSelector<Refund['refund_items_details']> = createSelector(
  getRefundedItems,
  flow(filter({ status: 'canceled', is_orphan: true }), map('order_item'))
);

export const getCanceledItems: OmsSelector<Order['items']> = createSelector(
  [getCanceledOrderItemsPubkeys, getOrderItems],
  (canceledOrderItemsPubkeys, items) =>
    map(
      canceledOrderItemsPubkey => find({ pubkey: canceledOrderItemsPubkey }, items),
      canceledOrderItemsPubkeys
    )
); // For Type "ITEMS" Array of canceled items

export const getDispatchableItems: OmsSelector<DispatchableItem[]> = createSelector(
  [getOrderItems, getCanceledItems],
  (orderItems, canceledItems) =>
    flow(
      filter(
        overSome([
          { variant: { is_formula: true } },
          { variant: { is_producible: true } },
          { variant: { is_bulky: true } },
        ])
      ),
      filter(item => !includes(item, canceledItems))
    )(orderItems)
); // needed for supplements and brushes);

export const getOrderGift: OmsSelector<GiftItem> = createSelector(getCurrentOrder, order =>
  find({ variant: { product: { type: 'digital_gift' } } }, order?.items)
);

export const hasBrush: OmsSelector<boolean> = createSelector(getCurrentOrder, order =>
  Boolean(some({ variant: { product: { type: 'brush' } } }, order?.items))
);

export const hasFormulas: OmsSelector<boolean> = createSelector(getCurrentOrder, order =>
  Boolean(find({ variant: { is_formula: true } }, order?.items))
);

export const hasGift: OmsSelector<boolean> = createSelector(getOrderGift, gift => Boolean(gift));
createSelector(getCurrentOrder, order =>
  Boolean(find({ variant: { is_producible: true } }, order?.items))
);

// hack to identify the percentage of the subscription discount that was applied to the order
export const getSubscriptionDiscount: OmsSelector<number> = createSelector(
  getCurrentOrder,
  order => {
    const subscriptionCouponCustomer = flow(
      get('coupon_customers'),
      find({ coupon: { coupon_property: 'subscription' } })
    )(order);

    return subscriptionCouponCustomer?.coupon?.amount || 0.15;
  }
);

export const getHasGiftCard: OmsSelector<boolean> = createSelector(
  getCurrentOrder,
  flow(get('coupon_customers'), some({ coupon: { origin: 'gift' } }))
);

export const getGiftCardPubkey: OmsSelector<string> = createSelector(
  getCurrentOrder,
  flow(get('coupon_customers'), find({ coupon: { origin: 'gift' } }), get('coupon'), get('gift'))
);

export const getHasLoyaltyGift: OmsSelector<boolean> = createSelector(
  getCurrentOrder,
  flow(get('items'), find(['variant.product.type', 'loyalty_gift']), Boolean)
);

export const getFormattedNonShippableItemsDetails: OmsSelector<
  FormattedNonShippableItemsDetails[]
> = createSelector(getNonShippableItems, items =>
  map(
    item => ({
      pubkey: item?.pubkey,
      type: {
        itemType: item?.variant.product.type,
        itemObject: null,
      },
      kind: capitalize(humanize(getItemName(item))),
      status: '',
      quantity: 1,
      price: {
        currency: null,
        amount: item?.unit_price,
      },
      subscription: null,
      total: {
        currency: null,
        isSubscription: item?.is_subscription,
        qty: item?.quantity,
        unitPrice: item?.unit_price,
      },
      modal: null,
      isProducible: item?.variant?.is_producible,
    }),
    items
  )
);
