import jwtDecode from 'jwt-decode';

import logSentryError from 'utils/sentry';

import AuthError from '../Errors/AuthError';
import AuthRefreshError from '../Errors/AuthRefreshError';
import AuthSigninError from '../Errors/AuthSigninError';
import HTTPError from '../Errors/HTTPError';
import * as SimpleApiClient from './SimpleApiClient';

const AUTH_KEY = 'authentication';

export const getAuth = () => {
  const value = localStorage.getItem(AUTH_KEY);
  return value && JSON.parse(value);
};

export const setAuth = auth => {
  localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
};

const isTokenExpired = token => {
  try {
    const payload = jwtDecode(token);
    const epoch = Math.round(Date.now() / 1000);
    return payload.exp <= epoch;
  } catch (err) {
    return true;
  }
};

export const refresh = async () => {
  let auth = null;

  try {
    auth = getAuth();
  } catch (e) {
    logSentryError('[Auth Service refresh] error', e);
    throw new AuthRefreshError('Cannot refresh token, must signin');
  }

  if (auth === null) {
    throw new AuthError('Unauthenticated');
  }
  if (!auth.refreshToken || isTokenExpired(auth.refreshToken)) {
    throw new AuthRefreshError('Cannot refresh token, must signin');
  }
  const params = { refresh: auth.refreshToken };
  let response = null;
  try {
    response = await SimpleApiClient.post('/v1/auth/token/refresh/', params);
    if (!response.ok) {
      throw new HTTPError(response.status, response.statusText);
    }
  } catch (err) {
    throw new AuthRefreshError('Cannot refresh token, must signin');
  }
  const result = await response.json();
  auth.accessToken = result.access;
  setAuth(auth);
  return auth;
};

/**
 * This is a legacy opinionated boolean check
 * @deprecated Please refrain from using, this will return falsy if the auth token is null (reverse of what one might expect)
 */
export const isAccessExpired = () => {
  const auth = getAuth();
  if (auth === null) {
    return null;
  }
  return isTokenExpired(auth.accessToken);
};

export const signin = async (username, password) => {
  const params = { type: 'staff', username, password };
  let response = null;
  try {
    response = await SimpleApiClient.post('/v1/auth/token/', params);
    if (!response.ok) {
      throw new HTTPError(response.status, response.statusText);
    }
  } catch (err) {
    throw new AuthSigninError('Invalid credentials');
  }
  const result = await response.json();
  const auth = {
    accessToken: result.access,
    refreshToken: result.refresh,
  };
  setAuth(auth);
  return auth;
};

export const signout = () => {
  const settings = localStorage.getItem('settings');
  localStorage.clear();
  localStorage.setItem('settings', settings);
};
