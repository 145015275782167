import { createApi } from '@reduxjs/toolkit/query/react';

import fetchJson from 'utils/custom-queries/fetchJson';

const backofficeApi = createApi({
  reducerPath: 'backofficeApi',
  baseQuery: fetchJson({ baseUrl: '/v1/backoffice' }),
  endpoints: () => ({}),
});

export default backofficeApi;
