import React from 'react';

import { ReactComponent as CanadaFlagSvg } from 'assets/flags/flag_ca.svg';

import type { CountryName } from '../constants/countries';

const countryToEmoji = {
  CA: <CanadaFlagSvg data-testid="canada-chip" style={{ height: '22px', marginLeft: '6px' }} />,
};

const getQualityHoldTitle = (shippingCountry: CountryName) => {
  return (
    <span>
      To Quality Hold
      {countryToEmoji[shippingCountry] ?? null}
    </span>
  );
};

export default getQualityHoldTitle;
