import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { Countries, CountriesState } from './types';

export const fetchCountries = createAsyncThunk<Countries>(
  'countries/fetchCountries',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/countries`);
      const countries: Countries = await response.json();
      return countries;
    } catch (err) {
      logSentryError('[fetchCountries] fetch countries', err);
      throw err;
    }
  }
);

const initialState: CountriesState = {
  status: 'idle',
  countries: [],
  error: null,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCountries.pending, draftState => {
        draftState.status = 'pending';
        draftState.countries = [];
        draftState.error = null;
      })
      .addCase(fetchCountries.fulfilled, (draftState, action) => {
        const countries = action.payload;

        draftState.status = 'fulfilled';
        draftState.countries = countries;
      })
      .addCase(fetchCountries.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = countriesSlice;

export default reducer;
