import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import split from 'lodash/fp/split';

import styled from '@mui/material/styles/styled';
import { useOmsSelector } from 'hooks/rtk';

import userHasRequiredPermissions from 'utils/userHasRequiredPermissions';

import * as FeatureFlagsSelectors from 'dux/featureFlags/selectors';
import * as TeammatesSelectors from 'dux/teammates/selectors';

import CenteredProgress from './CenteredProgress';
import ErrorComponent from './ErrorComponent';
import NotAllowedComponent from './NotAllowedComponent';

const StyledDiv = styled('div')`
  padding: 15px;
`;

type Props = {
  permissions: string[];
};

const PermissionRoute = ({ permissions = [] }: Props) => {
  const { pathname } = useLocation();

  const signedInPermissions = useOmsSelector(TeammatesSelectors.getSignedInTeammatePermissions);
  const teammateError = useOmsSelector(TeammatesSelectors.getError);
  const signedInProductionCells = useOmsSelector(
    TeammatesSelectors.getSignedInTeammateProductionCells
  );
  const signedInStatus = useOmsSelector(TeammatesSelectors.getStatus);
  const shouldShowFactory = useOmsSelector(FeatureFlagsSelectors.shouldShowFactory);

  if (includes(signedInStatus, ['idle', 'pending']) && isEmpty(signedInPermissions)) {
    return <CenteredProgress />;
  }
  if (signedInStatus === 'rejected') {
    return <ErrorComponent error={teammateError} />;
  }

  if (isEmpty(signedInPermissions)) {
    return <StyledDiv>You do not have any role assigned. Please, contact your manager</StyledDiv>;
  }

  if (
    shouldShowFactory &&
    isEmpty(signedInProductionCells) &&
    split('/', pathname)[1] === 'production'
  ) {
    return (
      <StyledDiv>
        You do not have production cells assigned. Please check with your manager
      </StyledDiv>
    );
  }

  if (userHasRequiredPermissions(signedInPermissions, permissions)) {
    return <Outlet />;
  }
  return <NotAllowedComponent necessaryPermissions={permissions} />;
};

export default PermissionRoute;
