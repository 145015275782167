import React from 'react';

import { Link } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';

import * as icons from './icons';

export const SearchBar = styled('div')`
  color: inherit;
  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 0)};
    padding-left: ${({ theme }) => `calc(1em + ${theme.spacing(4)})`};
    transition: ${({ theme }) => theme.transitions.create('width')};
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 12ch;
      &:focus {
        width: 20ch;
      }
    }
  }
  &:hover {
    background: ${({ theme }) => alpha(theme.palette.common.white, 0.25)};
  }
  & input {
    background: none;
    border: 0;
    color: inherit;
    display: block;
    font: inherit;
    margin: 0; // Reset for Safari
    padding: ${({ theme }) =>
      `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(5)}`};
    vertical-align: middle;
    white-space: normal;
    width: 100%;

    &:focus {
      outline: 2px solid #fffb;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1.6em;
      width: 1.6em;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><g stroke='%23FFF' stroke-width='1.2'><path d='M2 2 L8 8'/><path d='M2 8 L8 2'/></g></svg>");
    }
  }
`;

export const SearchIconContainer = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: ${({ theme }) => theme.spacing(5)};
`;

export const getSectionSuggestions = section => section?.suggestions;

export const renderSectionTitle = section => <strong data-sentry-unmask>{section.title}</strong>;

export const renderSuggestion = suggestion => {
  const iconMap = {
    salon: icons.SalonIcon,
    customer: icons.CustomerIcon,
    order: icons.OrderIcon,
    teammate: icons.TeammateIcon,
  };
  const icon = iconMap[suggestion.type];

  const pages = {
    customer: 'profile',
    order: 'formulas',
    salon: '',
  };

  return (
    <ListItemButton
      component={Link}
      to={`/${suggestion.type}s/${suggestion.pubkey}/${pages[suggestion.type]}`}
      data-testid={`${suggestion.type}-${suggestion.pubkey}`}
    >
      <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
      <ListItemText
        primary={suggestion.title}
        secondary={suggestion.subtitle}
        data-testid="suggestion-title"
        sx={{ wordWrap: 'break-word' }}
      />
    </ListItemButton>
  );
};

export const renderSuggestionsContainer = options => {
  const { containerProps, children } = options;
  const { key, ...restContainerProps } = containerProps;
  return (
    <Paper {...restContainerProps} square data-testid="suggestions-container">
      {children}
    </Paper>
  );
};
