import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useGoogleLogin } from '@react-oauth/google';
import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';
import { useSWRConfig } from 'swr';
import type { FetchingStatuses } from 'types/common';
import { isError } from 'types/predicates';

import { ReactComponent as GoogleLogo } from '../assets/google-icon.svg';
import signinBackgroundImg from '../assets/home-hero-skincare-d.jpg';

import Button from 'Components/Button';

import * as AuthSelectors from 'dux/auth/selectors';
import * as TeammatesSelectors from 'dux/teammates/selectors';
import * as AuthActions from 'dux/auth/thunks';
import type { SigninForm } from 'dux/auth/types';

import useNextFromLocationSearchString from './useNextFromLocationSearchString';

const StyledDialog = styled(Dialog)`
  text-align: center;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 20px 24px;
  & button {
    margin: 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-align: left;
  font-size: 28px;
`;

const GoogleButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-size: 16px;
  border: 1px solid #dedce0;
  color: #3c4043;
  padding: 6px 50px;
  text-align: center;
  justify-content: space-around;
  text-transform: none;
  margin: 0;
`;

const GoogleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
`;

const BackgroundImage = styled('img')`
  grid-area: hero;
  object-fit: cover;
  height: 100vh;
  width: 110%;
`;

const SigninScene = () => {
  const dispatch = useOmsDispatch();
  const navigate = useNavigate();
  const next = useNextFromLocationSearchString();
  const previousUsername: string = useOmsSelector(TeammatesSelectors.getSignedInTeammateUsername);

  const [fetchingStatus, setFetchingStatus] = useState<FetchingStatuses>('idle');
  const { mutate } = useSWRConfig();
  const [errorGoogle, setErrorGoogle] = useState<string | undefined>('');

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        dispatch(AuthActions.googleSignin(code)).unwrap();
        navigate(next as string);
      } catch (err) {
        if (!isError(err) || typeof err !== 'string') return;
        setErrorGoogle(err);
      }
    },
    onError: ({ error_description }) => {
      setErrorGoogle(error_description);
    },
    flow: 'auth-code',
  });

  const error = useOmsSelector(AuthSelectors.getError);

  return (
    <>
      <BackgroundImage alt="Signin background" src={signinBackgroundImg} />
      <StyledDialog open>
        <StyledDialogTitle>Prose Backoffice</StyledDialogTitle>
        <form
          onSubmit={async (e: React.FormEvent<HTMLFormElement> & SigninForm) => {
            e.preventDefault();
            await mutate(() => true, undefined, false); // Clear all the cache. SWR will revalidate upon re-render.
            setFetchingStatus('pending');
            try {
              const username = e.target?.username.value;
              const password = e.target?.password.value;
              await dispatch(AuthActions.signin({ username, password })).unwrap();
              setFetchingStatus('fulfilled');
              navigate(next as string);
            } catch (err) {
              setFetchingStatus('rejected');
            }
          }}
        >
          {' '}
          <DialogContent>
            <TextField
              variant="standard"
              autoFocus
              fullWidth
              id="username"
              label="Email Address (Username)"
              margin="dense"
              type="email"
              defaultValue={previousUsername}
              required
            />
            <TextField
              variant="standard"
              fullWidth
              id="password"
              label="Password"
              margin="dense"
              type="password"
              required
            />
            {fetchingStatus !== 'idle' && error && (
              <FormHelperText error>{error?.message}</FormHelperText>
            )}
          </DialogContent>
          <StyledDialogActions>
            <Button
              color="primary"
              dataTestId="signin"
              type="submit"
              variant="contained"
              isLoading={fetchingStatus === 'pending'}
              size={20}
              loaderColor="secondary"
              data-sentry-unmask
            >
              SignIn
            </Button>
          </StyledDialogActions>
        </form>
        <GoogleContainer>
          <GoogleButton onClick={login}>
            <GoogleLogo />
            <span>Sign in with Google</span>
          </GoogleButton>
          {errorGoogle && <FormHelperText error>{errorGoogle}</FormHelperText>}
        </GoogleContainer>
      </StyledDialog>
    </>
  );
};

export default SigninScene;
