import { createAsyncThunk } from '@reduxjs/toolkit';

import includes from 'lodash/fp/includes';

import { isError } from 'types/predicates';

import * as AuthService from 'services/Auth';
import * as SimpleApiClient from 'services/SimpleApiClient';

import logSentryError from 'utils/sentry';

import type { AuthToken } from './types';

const isInvalidCredentialsError = (error: Error): boolean =>
  includes(error?.message, ['Invalid credentials']);

export const fetchAuth = createAsyncThunk<AuthToken>('auth/fetchAuth', async _ => {
  try {
    const auth = await AuthService.getAuth();
    if (!auth) throw new Error('Unauthenticated');
    return auth;
  } catch (err) {
    logSentryError('[Auth Actions] fetch auth', err);
    throw err;
  }
});

export const refreshAuth = createAsyncThunk<AuthToken>('auth/refreshAuth', async _ => {
  try {
    const auth = await AuthService.refresh();
    return auth;
  } catch (err) {
    AuthService.signout();
    logSentryError('[Auth Actions] refresh auth', err);
    throw err;
  }
});

export const signin = createAsyncThunk<AuthToken, { username: string; password: string }>(
  'auth/signin',
  async ({ username, password }) => {
    try {
      const auth = await AuthService.signin(username, password);
      return auth;
    } catch (err) {
      // nothing to do about wrong user credentials, don't trigger sentry
      if (isError(err)) {
        if (!isInvalidCredentialsError(err)) {
          logSentryError('[Auth Actions] signin', err);
        }
      }

      throw err;
    }
  }
);

export const googleSignin = createAsyncThunk<AuthToken, string>('auth/googleSignin', async code => {
  try {
    const googleTokens = await SimpleApiClient.post(
      '/v1/backoffice/auth/social/jwt-pair/google-oauth2',
      {
        code,
      }
    );
    const tokens = await googleTokens.json();
    const auth = {
      accessToken: tokens.token,
      refreshToken: tokens.refresh,
    };
    AuthService.setAuth(auth);
    return auth;
  } catch (err) {
    logSentryError('[Auth Actions] googleSignin', err);
    throw err;
  }
});
