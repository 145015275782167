import { createAsyncThunk } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import type { Pages } from 'types/common';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import * as VariantsActions from '../variants/slice';
import type { DefectItemAndReason, Formula, Order, Orders } from './types';

export const fetchOrders = createAsyncThunk<{ orders: Orders; pages: Pages }, string>(
  'orders/fetchOrders',
  async (url, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(url);
      const pages: Pages = get('pages', query);
      const orders = await query.json();
      return { orders, pages };
    } catch (err) {
      logSentryError('[Orders Actions] fetch all orders', err);
      throw err;
    }
  }
);

export const fetchOrder = createAsyncThunk<Order, string>(
  'orders/fetchOrder',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/orders/${pubkey}/`);
      const order = await query.json();
      const variants = map('variant', order.items);
      dispatch(VariantsActions.dispatchVariants(variants));
      return order;
    } catch (err) {
      logSentryError('[Orders Actions] fetch order by pubkey', err);
      throw err;
    }
  }
);

export const fetchFormula = createAsyncThunk<Formula, string>(
  'orders/fetchFormula',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/formulas/${pubkey}`);
      const formula = await query.json();
      return formula;
    } catch (err) {
      logSentryError('[Orders Actions] fetch formula', err);
      throw err;
    }
  }
);

export const defectItemFromOrderDetail = createAsyncThunk<
  string,
  { selectedItemWithReason: DefectItemAndReason; boxPubkeyFromOrderDetail: string }
>('orders/defectItemFromOrderDetail', async (params, { dispatch }) => {
  try {
    const { selectedItemWithReason: itemToDefect, boxPubkeyFromOrderDetail: boxPubkey } = params;
    const APIClient = initApiClient(dispatch);
    const query = await APIClient.post(`/v1/backoffice/boxes/${boxPubkey}/trash/`, {
      reason: itemToDefect.reason,
      production_items: [itemToDefect.pubkey],
    });
    const response = await query.json();
    return response;
  } catch (err) {
    logSentryError('[Orders Actions] defect item from order details', err);
    throw err;
  }
});
