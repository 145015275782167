import type { Middleware } from '@reduxjs/toolkit';

import assign from 'lodash/fp/assign';
import includes from 'lodash/fp/includes';

import { clearParam, loadParam, saveParam } from '../utils/localStorage';

import { PRODUCTION_SET_FACTORY } from 'dux/production/actionTypes';
import * as SettingsActions from 'dux/settings/slice';

const mapModeToFieldName = {
  lane: {
    targetKey: 'selectedLane',
    localStorageKey: 'settings.production.defaultLab',
    reset: SettingsActions.updateSelectedLane,
  },
  bufferSpace: {
    targetKey: 'selectedBufferSpace',
    localStorageKey: 'settings.bufferSpaces.default',
    reset: SettingsActions.updateSelectedBufferSpace,
  },
};

const localStorageMiddleware: Middleware = () => dispatch => action => {
  if (action.type === PRODUCTION_SET_FACTORY) {
    const { mode, factory } = action;
    const settings = loadParam('settings');

    saveParam('defaultFactory', factory);

    if (!includes(mode, ['lane', 'bufferSpace'])) return dispatch(action);

    const { targetKey, localStorageKey, reset } = mapModeToFieldName[mode];

    clearParam(localStorageKey);
    const updatedSettings = assign(settings, { [targetKey]: '' });
    saveParam('settings', updatedSettings);

    dispatch(reset(''));
  }

  return dispatch(action);
};

export default localStorageMiddleware;
