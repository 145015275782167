import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { FormState } from 'final-form';
import type { Notification } from 'types/common';

import type { PartialRefundFormValues, PartialRefundState } from './types';

const initialState: PartialRefundState = {
  partialRefundForm: {},
  status: 'idle',
  notification: {
    message: '',
    severity: null,
  },
};

const partialRefundSlice = createSlice({
  name: 'partialRefund',
  initialState,
  reducers: {
    updateFormState(
      draftState,
      action: PayloadAction<{
        form: string;
        state: FormState<Record<string, any>, Partial<PartialRefundFormValues>>;
      }>
    ) {
      const { state } = action.payload;
      draftState.partialRefundForm = state;
    },
    setNotification(draftState, action: PayloadAction<Notification>) {
      const { message, severity } = action.payload;
      draftState.notification.message = message;
      draftState.notification.severity = severity;
    },
    closeSnackbar(draftState) {
      draftState.notification.message = '';
      draftState.notification.severity = null;
    },
  },
});

const { reducer, actions } = partialRefundSlice;

export const { setNotification, closeSnackbar } = actions;

export default reducer;
