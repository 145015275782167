import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';
import { useSWRConfig } from 'swr';
import type { FetchingStatuses } from 'types/common';

import Button from '../Components/Button';

import * as AuthSelectors from 'dux/auth/selectors';
import * as TeammatesSelectors from 'dux/teammates/selectors';
import * as AuthActions from 'dux/auth/thunks';
import type { SigninForm } from 'dux/auth/types';

import useNextFromLocationSearchString from './useNextFromLocationSearchString';

const StyledDialog = styled(Dialog)`
  text-align: center;
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-align: left;
`;

const SigninWithE2E = () => {
  const navigate = useNavigate();
  const dispatch = useOmsDispatch();
  const [fetchingStatus, setFetchingStatus] = useState<FetchingStatuses>('idle');
  const { mutate } = useSWRConfig();
  const next = useNextFromLocationSearchString();

  const previousUsername: string = useOmsSelector(TeammatesSelectors.getSignedInTeammateUsername);
  const error = useOmsSelector(AuthSelectors.getError);

  return (
    <StyledDialog open>
      <StyledDialogTitle>E2E Sign in</StyledDialogTitle>
      <form
        onSubmit={async (e: React.FormEvent<HTMLFormElement> & SigninForm) => {
          e.preventDefault();
          await mutate(() => true, undefined, false); // Clear all the cache. SWR will revalidate upon re-render.
          setFetchingStatus('pending');
          try {
            const username = e.target.username.value;
            const password = e.target.password.value;
            await dispatch(AuthActions.signin({ username, password })).unwrap();
            setFetchingStatus('fulfilled');
            navigate(next as string);
          } catch (err) {
            setFetchingStatus('rejected');
          }
        }}
      >
        {' '}
        <DialogContent>
          <TextField
            variant="standard"
            autoFocus
            fullWidth
            id="username"
            label="Email Address (Username)"
            margin="dense"
            type="email"
            defaultValue={previousUsername}
            required
          />
          <TextField
            variant="standard"
            fullWidth
            id="password"
            label="Password"
            margin="dense"
            type="password"
            required
          />
          {fetchingStatus !== 'idle' && error && (
            <FormHelperText error>{error?.message}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            dataTestId="signin"
            type="submit"
            variant="contained"
            isLoading={fetchingStatus === 'pending'}
            size={20}
            loaderColor="secondary"
            data-sentry-unmask
          >
            SignIn
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

export default SigninWithE2E;
