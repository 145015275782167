import HTTPError from 'Errors/HTTPError';
import type { HTTPErrorType } from 'Errors/HTTPErrorType';

export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

export function isHTTPError(err: unknown): err is HTTPErrorType {
  return err instanceof HTTPError;
}
