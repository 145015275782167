import React, { type ReactNode, useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import forEach from 'lodash/fp/forEach';

import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';

import { defaultSettings } from '../Apps/Settings/content';

import { isAccessExpired } from 'services/Auth';

import { loadParam, saveParam } from 'utils/localStorage';

import * as AuthSelectors from 'dux/auth/selectors';
import * as AuthActions from 'dux/auth/thunks';

import CenteredProgress from './CenteredProgress';

declare const window: {
  Cypress: any;
} & Window;

type Props = { children: ReactNode };

const Authentication = ({ children }: Props) => {
  const dispatch = useOmsDispatch();
  const accessToken = useOmsSelector(AuthSelectors.getAccessToken);
  const status = useOmsSelector(AuthSelectors.status);

  const consolidateSettings = () => {
    const settings = loadParam('settings') || defaultSettings;
    forEach(defaultKey => {
      if (!settings[defaultKey]) {
        settings[defaultKey] = defaultSettings[defaultKey];
      }
    }, defaultSettings);
    saveParam('settings', settings);
  };

  useEffect(() => {
    if (isAccessExpired()) {
      dispatch(AuthActions.refreshAuth());
    }
    consolidateSettings();
  }, [dispatch]);

  if (status === 'pending') {
    return <CenteredProgress />;
  }

  if (!accessToken) {
    if (window.Cypress) {
      return (
        <Navigate
          to={{
            pathname: '/auth/signin/e2e',
            search: `?next=${encodeURIComponent(document.location.pathname)}`,
          }}
        />
      );
    }
    return (
      <Navigate
        to={{
          pathname: '/auth/signin',
          search: `?next=${encodeURIComponent(document.location.pathname)}`,
        }}
      />
    );
  }
  return children;
};

export default Authentication;
