import { createAsyncThunk } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import type { RootState } from 'store';

import initApiClient from 'services/ApiClient';

import { prodItemsBulkUpdate, prodItemsSequentialUpdate } from 'utils/promiseProditemUpdate';
import logSentryError from 'utils/sentry';

import type { BufferBins, SingleFetchedBufferBin } from './types/bufferSpaces-bufferBins-types';
import type { BufferSpaces } from './types/bufferSpaces-list-types';
import type { ProdItem } from './types/slice-types';
import { getPreviousCallParams } from './selectors';

type ActionBufferBinArgs = {
  proditems: Array<ProdItem>;
  boxPubkey: string;
  status: string;
};

type BufferBinsArgs = {
  bufferSpacePubkey: string;
  selectedStatus: string;
  sortParam: string;
};

export const fetchBufferSpaces = createAsyncThunk<BufferSpaces, void>(
  'bufferSpaces/fetchBufferSpaces',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get('/v1/backoffice/buffer_spaces');
      const bufferSpaces = await query.json();
      return bufferSpaces;
    } catch (err) {
      logSentryError('[BufferSpaces Actions] fetch buffer spaces', err);
      throw err;
    }
  }
);

export const fetchBufferBins = createAsyncThunk<BufferBins, BufferBinsArgs, { state: RootState }>(
  'bufferSpaces/fetchBufferBins',
  async (bufferBinsArgs, { dispatch }) => {
    try {
      const { bufferSpacePubkey, selectedStatus, sortParam } = bufferBinsArgs;
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(
        `/v1/backoffice/buffer_spaces/${bufferSpacePubkey}/?buffer_bins__status=${selectedStatus}&ordering=${sortParam}`
      );
      const response = await query.json();
      return response.buffer_bins;
    } catch (err) {
      logSentryError('[BufferSpaces Actions] fetch buffer bins', err);
      throw err;
    }
  },
  {
    condition(args, { getState }) {
      const previousCallParams = getPreviousCallParams(getState());
      const currentCallParams = JSON.stringify(args);

      return previousCallParams !== currentCallParams;
    },
  }
);

export const fetchBufferBin = createAsyncThunk<
  SingleFetchedBufferBin,
  string,
  { state: RootState }
>('bufferSpaces/fetchBufferBin', async (pubkey, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const binResponse = await APIClient.get(`/v1/backoffice/buffer_bins/${pubkey}`);
    const binParsed = await binResponse.json();
    return binParsed;
  } catch (err) {
    logSentryError('[BufferSpaces Actions] fetch buffer bin', err);
    throw err;
  }
});

const getBufferedProdItems = (prodItems: Array<ProdItem>): Array<ProdItem> =>
  filter({ status: { value: 'buffered' } }, prodItems);

export const actionBufferBin = createAsyncThunk<void, ActionBufferBinArgs>(
  'bufferSpaces/actionBufferBin',
  async (args, { dispatch }) => {
    try {
      const { proditems, boxPubkey, status } = args;
      const bufferedProditems = getBufferedProdItems(proditems);
      const APIClient = initApiClient(dispatch);
      if (isEmpty(bufferedProditems)) return;
      if (boxPubkey) {
        await prodItemsBulkUpdate(APIClient, bufferedProditems, status, boxPubkey);
      } else {
        await prodItemsSequentialUpdate(APIClient, bufferedProditems, status);
      }
    } catch (err) {
      logSentryError('[actionBufferBin]', err);
      throw err;
    }
  }
);
