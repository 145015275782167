import { createAsyncThunk } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';

import type { Pages } from 'types/common';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { GiftDetailItem } from './types/gift-detail-types';
import type { GiftListItems, GiftsStats } from './types/gifts-list-types';
import type { UpdateGiftParams } from './types/slice-types';

export const fetchGifts = createAsyncThunk<{ gifts: GiftListItems; pages: Pages }, string>(
  'gifts/fetchGifts',
  async (url, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(url);
      const pages: Pages = get('pages', query);
      const gifts = await query.json();
      return { gifts, pages };
    } catch (err) {
      logSentryError('[Gift Actions] fetch all gifts', err);
      throw err;
    }
  }
);

export const fetchGift = createAsyncThunk<GiftDetailItem, string>(
  'gifts/fetchGift',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(`/v1/backoffice/gifts/${pubkey}/`);
      const gift = await query.json();
      return gift;
    } catch (err) {
      logSentryError('[Gift Actions] fetch gift by pubkey', err);
      throw err;
    }
  }
);

export const updateGift = createAsyncThunk<GiftDetailItem, UpdateGiftParams>(
  'gifts/updateGift',
  async (params, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const { pubkey, consolidatedParams } = params;
      const response = await APIClient.patch(`/v1/backoffice/gifts/${pubkey}`, consolidatedParams);
      const updatedGift = await response.json();
      return updatedGift;
    } catch (err) {
      logSentryError('[Gift Actions] update gift', err);
      throw err;
    }
  }
);

export const fetchGiftsStats = createAsyncThunk<GiftsStats>(
  'gifts/fetchGiftsStats',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get('/v1/backoffice/gifts/stats');
      const stats = await query.json();
      return stats;
    } catch (err) {
      logSentryError('[Gift Actions] fetch gifts stats', err);
      throw err;
    }
  }
);
