// All these utils are expecting variant object or list of variant objects
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import _ from 'lodash/fp/placeholder';

export const variantIsSupplement = flow(
  get('product.type'),
  includes(_, ['supplement_core', 'supplement_booster'])
);

export const variantIsSupplementJar = flow(
  get('slug'),
  includes(_, [
    'supplement_core_0001',
    'supplement_core_0002',
    'supplement_core_0003',
    'supplement_core_0004',
    'supplement_core_0006',
    'supplement_booster_hairgrowth',
    'supplement_booster_hairgrowth_v2',
  ])
);
