/*
 * Round to specified precision, taking care of decimal rounding error
 * `safeRound(1.005, 2)` -> 1.01 (Math.round would have this one incorrect)
 * source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#A_better_solution
 */
import split from 'lodash/fp/split';

export default function safeRound(number, precision) {
  const shift = (num, prec) => {
    const numArray = split('e', `${num}`);
    return +`${numArray[0]}e${numArray[1] ? +numArray[1] + prec : prec}`;
  };
  return shift(Math.round(shift(number, +precision)), -precision);
}
