import type { BaseQueryApi } from '@reduxjs/toolkit/query';

import initApiClient from './ApiClient';
import type { HTTPMethod } from './ApiClient.types';

const createApiClient = (dispatch: BaseQueryApi['dispatch'], method: HTTPMethod) => {
  const APIClient = initApiClient(dispatch);

  if (method === 'DELETE') return APIClient.del;

  return APIClient[method.toLowerCase()];
};

export default createApiClient;
