import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { Box, Boxes, BoxesState } from './types';

export const fetchBoxes = createAsyncThunk<Boxes, string>(
  'boxes/fetchBoxes',
  async (orderPubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/orders/${orderPubkey}/boxes/`);
      const boxes: Boxes = await response.json();
      return boxes;
    } catch (err) {
      logSentryError('[fetchBoxes] fetch boxes', err);
      throw err;
    }
  }
);

const initialState: BoxesState = {
  status: 'idle',
  boxes: [],
  error: null,
};

const boxesSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    dispatchBox(draftState, action: PayloadAction<Box[]>) {
      const box = action.payload;
      draftState.boxes = box;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBoxes.pending, draftState => {
        draftState.status = 'pending';
        draftState.boxes = [];
        draftState.error = null;
      })
      .addCase(fetchBoxes.fulfilled, (draftState, action) => {
        const boxes = action.payload;

        draftState.status = 'fulfilled';
        draftState.boxes = boxes;
      })
      .addCase(fetchBoxes.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer, actions } = boxesSlice;

export const { dispatchBox } = actions;

export default reducer;
