import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import assign from 'lodash/fp/assign';

import { loadParam, saveParam } from 'utils/localStorage';

import type { MetadataState } from './types';

const state: MetadataState = {
  sectionTitle: null,
};

const loadedFromlocalStorage: MetadataState = loadParam('metadata');
const initialState: MetadataState = assign(state, loadedFromlocalStorage);
saveParam('metadata', initialState);

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setSectionTitle(draftState, action: PayloadAction<string>) {
      draftState.sectionTitle = action.payload;
    },
  },
});

const { reducer, actions } = metadataSlice;

export const { setSectionTitle } = actions;

export default reducer;
