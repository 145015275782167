import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';

import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

type FastConsultationState = RootState['fastconsultation'];

const getFastConsultationState: OmsSelector<FastConsultationState> = state =>
  state.fastconsultation;

export const getAccessToken: OmsSelector<FastConsultationState['accessToken']> = createSelector(
  getFastConsultationState,
  get('accessToken')
);

export const getAuth: OmsSelector<FastConsultationState['accessToken']> = state => {
  const token = getAccessToken(state);
  if (!token) {
    throw new Error('Missing Token');
  }
  return token;
};

export const getCustomer: OmsSelector<FastConsultationState['customer']> = createSelector(
  getFastConsultationState,
  get('customer')
);

export const getHairProfile: OmsSelector<FastConsultationState['hairProfile']> = createSelector(
  getFastConsultationState,
  get('hairProfile')
);

export const getStatus: OmsSelector<FastConsultationState['status']> = createSelector(
  getFastConsultationState,
  get('status')
);

export const getSnackbar: OmsSelector<FastConsultationState['snackbar']> = createSelector(
  getFastConsultationState,
  get('snackbar')
);
