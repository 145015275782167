import { createAsyncThunk } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';
import uniq from 'lodash/fp/uniq';
import update from 'lodash/fp/update';

import { capitalize, humanize } from 'helpers';
import type { RootState } from 'store';
import type { Nullable, Pages } from 'types/common';
import { isError } from 'types/predicates';

import initApiClient from 'services/ApiClient';

import getJson from 'utils/getJson';
import logSentryError from 'utils/sentry';
import { sortInDisplayOrder } from 'utils/sortInDisplayOrder';

import type {
  LogReasons,
  ShippingAddress,
  Stats,
  SubscriptionDetail,
  Subscriptions,
} from './types';

const sortSubscriptionItems = update('regular_products', sortInDisplayOrder);

export const fetchSubscriptions = createAsyncThunk<
  { subscriptions: Subscriptions; pages: Pages },
  string
>('subscriptions/fetchSubscriptions', async (url, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const query = await APIClient.get(url);
    const subscriptions = await query.json();
    const pages: Pages = get('pages', query);
    return { subscriptions, pages };
  } catch (err) {
    logSentryError('[Subscriptions Actions] fetch subscriptions', err);
    throw err;
  }
});

export const fetchCurrentSubscriptionDetail = createAsyncThunk<
  {
    currentSubscription: SubscriptionDetail;
  },
  string | undefined
>('subscriptions/fetchCurrentSubscription', async (pubkey, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const query = await APIClient.get(`/v1/backoffice/subscriptions/${pubkey}/`);
    const currentSubscription = sortSubscriptionItems(await query.json());

    return {
      currentSubscription: sortSubscriptionItems(currentSubscription),
    };
  } catch (err) {
    logSentryError('[Subscriptions Actions] fetch current subscription', err);
    throw err;
  }
});

export const fetchSubscriptionsDetail = createAsyncThunk<
  {
    subscriptionsDetail: SubscriptionDetail[];
  },
  SubscriptionDetail
>('subscriptions/fetchSubscriptionsDetail', async (currentSubscription, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);

    const subscriptionsQuery = await APIClient.get(
      `/v1/backoffice/customers/${currentSubscription.customer.pubkey}/subscriptions/`
    );

    const allSubscriptions = await subscriptionsQuery.json();

    const pubkeysLeftToFetch = filter(
      v => v !== currentSubscription.pubkey,
      map('pubkey', allSubscriptions)
    );

    if (isEmpty(pubkeysLeftToFetch)) {
      return {
        subscriptionsDetail: [currentSubscription],
      };
    }

    const subscriptionPromises = await Promise.all(
      map(pkey => APIClient.get(`/v1/backoffice/subscriptions/${pkey}/`), pubkeysLeftToFetch)
    );
    const fetchedSubscriptions = await getJson(subscriptionPromises);

    const subscriptionsDetail = reduce(
      (acc, currPkey) => {
        // the individual subscription item (was fetched or previously cached)
        const subscriptionDetail = find({ pubkey: currPkey }, fetchedSubscriptions);
        // merging both items to get the full info
        return uniq([...acc, currentSubscription, { ...subscriptionDetail }]);
      },
      [],
      pubkeysLeftToFetch
    );

    return {
      subscriptionsDetail: sortSubscriptionItems(subscriptionsDetail),
    };
  } catch (err) {
    logSentryError('[Subscriptions Actions] fetch all subscriptions', err);
    throw err;
  }
});

export const fetchSubscriptionStats = createAsyncThunk<Stats>(
  'subscriptions/fetchSubscriptionStats',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get('/v1/backoffice/subscriptions/stats');
      const stats = await response.json();
      return stats;
    } catch (err) {
      logSentryError('[Subscriptions Actions] fetch subscription stats', err);
      throw err;
    }
  }
);

export const fetchLogReasons = createAsyncThunk<LogReasons>(
  'subscriptions/fetchLogReasons',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get('/v1/backoffice/subscription_log_reasons/');
      const logReasons = await response.json();
      return logReasons;
    } catch (err) {
      logSentryError('[Subscriptions Actions] fetch subscription canceled reasons', err);
      throw err;
    }
  }
);

export const fetchShippingAddress = createAsyncThunk<
  ShippingAddress,
  { customerPubkey: string | undefined; shippingAddressPubkey: string | undefined },
  { state: RootState }
>(
  'subscriptions/fetchShippingAddress',
  async ({ customerPubkey, shippingAddressPubkey }, { dispatch }) => {
    const APIClient = initApiClient(dispatch);
    try {
      const response = await APIClient.get(
        `/v1/backoffice/customers/${customerPubkey}/addresses/${shippingAddressPubkey}`
      );
      const address = await response.json();
      return address;
    } catch (err) {
      // bail to avoid error logging
      if (isError(err) && err.name === 'AbortError') throw err;
      logSentryError('[Subscriptions Actions] fetch shipping address', err);
      throw err;
    }
  }
);

const prettyPrintCoupon = coupon => {
  if (isEmpty(coupon.label)) return capitalize(humanize(coupon.origin));
  return coupon.label;
};

export const fetchDynamicDiscountPercentage = createAsyncThunk<
  { roundedDiscount: Nullable<number>; tooltipText: String[] },
  string
>('subscriptions/fetchDynamicDiscountPercentage', async (subscriptionPubkey, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const response = await APIClient.get(
      `/v1/backoffice/subscriptions/${subscriptionPubkey}/preview/`
    );
    const order = await response.json();
    const coupons = get('ticket.coupons', order);
    const discountCoupons = filter(
      coupon => coupon.total_price !== 0 && coupon.type === 'percentage',
      coupons
    );
    const tooltipText: String[] = map(
      coupon => `${prettyPrintCoupon(coupon)} : ${coupon.amount * 100}%`
    )(discountCoupons);
    const discount = reduce((sum, coupon) => sum + coupon.amount, 0)(discountCoupons);
    const roundedDiscount = Number(discount.toFixed(2)) * 100;
    return { roundedDiscount, tooltipText };
  } catch (err) {
    logSentryError('[Subscriptions Actions] fetch discount percentage', err);
    throw err;
  }
});
