import { createAsyncThunk } from '@reduxjs/toolkit';

import assign from 'lodash/fp/assign';
import getOr from 'lodash/fp/getOr';
import keys from 'lodash/fp/keys';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';

import initApiClient from 'services/ApiClient';

import getJson from 'utils/getJson';
import logSentryError from 'utils/sentry';

import type { Category, Formulaset, Scorings, Survey } from './types';

export const fetchSurvey = createAsyncThunk<{ survey: Survey }, string>(
  'surveys/fetchSurvey',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const promises = await Promise.all([
        APIClient.get(`/v1/backoffice/profile/questions`),
        pubkey ? APIClient.get(`/v1/backoffice/surveys/${pubkey}`) : Promise.resolve(),
      ]);

      const [surveyQuestions, surveyAnswers]: [Record<Category, String[]>, Survey] = await getJson(
        promises
      );

      const surveyByCategories = map(
        (category: Category) =>
          reduce(
            (acc, value) =>
              assign(acc, {
                category,
                created_at: surveyAnswers.created_at,
                updated_at: surveyAnswers.updated_at,
                [value]: surveyAnswers[value],
              }),
            {},
            surveyQuestions[category]
          ),
        keys(surveyQuestions)
      );

      return { survey: { ...surveyAnswers, surveyByCategories } };
    } catch (err) {
      logSentryError('[Survey Actions] fetch survey', err);
      throw getOr(err, 'body.detail', err);
    }
  }
);

export const fetchFormulaset = createAsyncThunk<Formulaset, string>(
  'surveys/fetchFormulaset',
  async (formulasetUrl, { dispatch }) => {
    try {
      if (!formulasetUrl) return null;
      const APIClient = initApiClient(dispatch);
      const query = await APIClient.get(formulasetUrl);
      const formulaset = await query.json();

      return formulaset;
    } catch (err) {
      logSentryError('[Survey Actions] fetch formulaset', err);
      throw err;
    }
  }
);

export const fetchScoring = createAsyncThunk<Scorings, Formulaset['scoring']>(
  'surveys/fetchScoring',
  async (formulasetScoring, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);

      if (!formulasetScoring) return null;

      const query = await APIClient.get(`/v1/backoffice/survey/scoring_categories`);
      const scoringCategories = await query.json();

      const scoring = map(
        category =>
          reduce(
            (acc, value) =>
              assign(acc, {
                category,
                [value]: formulasetScoring[value],
              }),
            {},
            scoringCategories[category]
          ),
        keys(scoringCategories)
      );

      return scoring;
    } catch (err) {
      logSentryError('[Survey Actions] fetch scoring', err);
      throw err;
    }
  }
);
