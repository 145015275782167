import type { Code, ErrorBody, HTTPErrorType } from './HTTPErrorType';

export default class HTTPError extends Error implements HTTPErrorType {
  code: Code;

  body: ErrorBody;

  constructor(code: Code, body: ErrorBody, ...params: Array<string | undefined>) {
    super(...params);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }
    this.code = code;
    this.body = body;
  }
}
