import flatten from 'lodash/fp/flatten';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import range from 'lodash/fp/range';

import type { APIClient as APIClientType } from 'services/ApiClient.types';
import type { IApiResponse } from 'services/ApiResponse.types';

import getJson from './getJson';

type PageFetcher = (
  url: string,
  APIClient: APIClientType
) => (page: number) => Promise<IApiResponse>;

const createFetchPage: PageFetcher =
  (url, APIClient) =>
  async (pageNumber = 1) =>
    APIClient.get(`${url}?page=${pageNumber}`);

async function fetchAllPages<T>(url: string, APIClient: APIClientType): Promise<T[]> {
  const fetchPage = createFetchPage(url, APIClient);

  const initialResponse = await fetchPage(1); // first page is always 1

  const resultsPage1: T[] = await initialResponse.json();
  /* 'pages' property coming from our APIResponse class,
   which parses the 'link' header in response */
  const lastPageUrl = get('pages.last', initialResponse);

  if (!lastPageUrl) return resultsPage1;

  // extract the last page number from the URL e.g. /v1/backoffice/ingredients/?page=3
  const matches = lastPageUrl.match(/\?page=(\d+)/);
  const lastPageNumber = Number(matches[matches.length - 1]);

  const pagesToFetch = range(2, lastPageNumber + 1); // [2, 3, ..., lastPageNumber]

  const subsequentResponses: Array<IApiResponse> = await Promise.all(map(fetchPage, pagesToFetch));

  const results = await getJson<T[]>(subsequentResponses);

  return resultsPage1.concat(flatten(results));
}

export default fetchAllPages;
