import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

declare global {
  interface Window {
    localStorage: any;
  }
}

// Alias localStorage on window object to make sure
// its usable everywhere, even on Safari iOS10 Private
// browsing mode.
// Should always use the aliased version everywhere.
try {
  // Sniff feature to make sure we detect localStorage presence
  const sentinel = '___fake_key___';
  window.localStorage.setItem(sentinel, sentinel);
  window.localStorage.removeItem(sentinel);
} catch (error) {
  window.localStorage = {
    _data: {},
    setItem: function (id, val) {
      return (this._data[id] = String(val));
    },
    getItem: function (id) {
      return this._data.hasOwnProperty(id) ? this._data[id] : null;
    },
    removeItem: function (id) {
      return delete this._data[id];
    },
    clear: function () {
      return (this._data = {});
    },
  };
}
