import { createSlice } from '@reduxjs/toolkit';

import { fetchAuth, googleSignin, refreshAuth, signin } from './thunks';
import type { AuthState } from './types';

const initialState: AuthState = {
  status: 'idle',
  accessToken: null,
  refreshToken: null,
  isSignedIn: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signout(draftState) {
      draftState.accessToken = null;
      draftState.refreshToken = null;
      draftState.isSignedIn = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAuth.pending, draftState => {
        draftState.status = 'pending';
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.error = null;
        draftState.isSignedIn = false;
      })
      .addCase(fetchAuth.fulfilled, (draftState, action) => {
        const { accessToken, refreshToken } = action.payload;

        draftState.status = 'fulfilled';
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
        draftState.isSignedIn = true;
      })
      .addCase(fetchAuth.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(refreshAuth.pending, draftState => {
        draftState.status = 'pending';
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.isSignedIn = false;
        draftState.error = null;
      })
      .addCase(refreshAuth.fulfilled, (draftState, action) => {
        const { accessToken, refreshToken } = action.payload;

        draftState.status = 'fulfilled';
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
        draftState.isSignedIn = true;
      })
      .addCase(refreshAuth.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(signin.pending, draftState => {
        draftState.status = 'pending';
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.error = null;
        draftState.isSignedIn = false;
      })
      .addCase(signin.fulfilled, (draftState, action) => {
        const { accessToken, refreshToken } = action.payload;

        draftState.status = 'fulfilled';
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
        draftState.isSignedIn = true;
      })
      .addCase(signin.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(googleSignin.pending, draftState => {
        draftState.status = 'pending';
        draftState.accessToken = null;
        draftState.refreshToken = null;
        draftState.error = null;
        draftState.isSignedIn = false;
      })
      .addCase(googleSignin.fulfilled, (draftState, action) => {
        const { accessToken, refreshToken } = action.payload;

        draftState.status = 'fulfilled';
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
        draftState.isSignedIn = true;
      })
      .addCase(googleSignin.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer, actions } = authSlice;

export const { signout } = actions;

export default reducer;
