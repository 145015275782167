import React from 'react';

import { Link } from 'react-router-dom';

import camelCase from 'lodash/fp/camelCase';
import map from 'lodash/fp/map';
import startCase from 'lodash/fp/startCase';

import { keyframes } from '@emotion/react';

import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { useOmsSelector } from 'hooks/rtk';

import * as TeammatesSelectors from 'dux/teammates/selectors';

const floating = keyframes`
  to {
    top: 1.5rem;
  }
`;

const Gandalf = styled('div')`
  transform: scale(0.5);
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  margin-top: -100px;
  top: 1rem;
  animation: ${floating} 1s infinite alternate ease-in-out;
  div {
    position: absolute;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background-color: #1a2130;
    border-radius: 50%;
  }
`;

const Fireball = styled('div')`
  bottom: -10px;
  left: 50px;
  width: 300px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(#efac41, #de8531, #6c1305, black);
  border: 5px solid #000;
`;

const Skirt = styled('div')`
  bottom: 50px;
  left: 100px;
  border-bottom: 230px solid #ededed;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  filter: drop-shadow(0 0 6px darken(#ededed, 10%));
`;

const Sleeves = styled('div')`
  &::before,
  &::after {
    // sleeves
    content: '';
    position: absolute;
    border-bottom: 70px solid #ededed;
    filter: drop-shadow(0 0 6px darken(#ededed, 10%));
  }
  &::before {
    // sleeve left
    top: 130px;
    left: 191px;
    border-left: 100px solid transparent;
    border-right: 40px solid transparent;
    transform: rotate(-34deg);
  }
  &::after {
    // sleeve right
    top: 127px;
    left: 70px;
    border-left: 40px solid transparent;
    border-right: 100px solid transparent;
    transform: rotate(41deg);
  }
`;

const Shoulders = styled('div')`
  background-color: #ededed;
  border-radius: 50%;
  width: 100px;
  height: 130px;
  left: 150px;
  top: 120px;
`;

const Hand = styled('div')`
  width: 33px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffd8ad;
  top: -6px;
`;

const HandLeft = styled(Hand)`
  left: -70px;
  transform: rotate(-20deg);
  &::after {
    // sword
    content: '';
    position: absolute;
    background-color: #e6e6e6;
    width: 126px;
    height: 8px;
    border-radius: 4px;
    transform: rotate(-105deg);
    transform-origin: bottom;
    top: -48px;
    left: -56px;
  }
`;

const HandRight = styled(Hand)`
  right: -70px;
  transform: rotate(20deg);
  &::after {
    // staff
    content: '';
    position: absolute;
    background-color: #bf5507;
    width: 250px;
    height: 5px;
    border-radius: 2.5px;
    transform: rotate(-78deg);
    transform-origin: left;
    bottom: -100px;
    left: 0;
  }
`;

const Head = styled('div')`
  width: 80px;
  height: 90px;
  top: 80px;
  left: 160px;
  background-color: #ffd8ad;
  border-radius: 50%;

  &::before,
  &::after {
    // eyes
    content: '';
    position: absolute;
    background-color: #000;
  }

  &::before {
    width: 13px;
    height: 5px;
    border-radius: 3px;
    top: 42px;
    left: 22px;
    transform: rotate(19deg);
  }
  &::after {
    width: 13px;
    height: 5px;
    border-radius: 3px;
    top: 42px;
    right: 22px;
    transform: rotate(-19deg);
  }
`;

const Hair = styled('div')`
  width: 70px;
  height: 30px;
  background-color: #c2beb5;
  border-radius: 50%;
  top: 0px;
  left: 5px;

  &::before,
  &::after {
    // hair sides
    content: '';
    position: absolute;
    background-color: #c2beb5;
    filter: drop-shadow(2px 5px 0 darken(#c2beb5, 10%));
  }
  &::before {
    // hair left
    top: 13px;
    left: -16px;
    width: 25px;
    height: 100px;
    border-top-left-radius: 34px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 20px;
    transform: rotate(8deg);
  }
  &::after {
    // hair right
    top: 13px;
    right: -16px;
    width: 25px;
    height: 100px;
    border-top-left-radius: 15px;
    border-top-right-radius: 34px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 100px;
    transform: rotate(-10deg);
  }
`;

const Beard = styled('div')`
  top: 64px;
  left: 5px;
  border-top: 80px solid #c2beb5;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-radius: 30px;
  filter: drop-shadow(2px 5px 0 darken(#c2beb5, 10%));

  &::before {
    // mouth
    content: '';
    position: absolute;
    background-color: pink;
    width: 20px;
    height: 5px;
    border-radius: 40%;
    top: -70px;
    left: -9px;
  }
`;

const Message = styled('div')`
  max-width: 700px;
  margin: 15px auto 0 auto;
  text-align: center;
`;

type Props = { necessaryPermissions: string[] };

const NotAllowedComponent = ({ necessaryPermissions = [] }: Props) => {
  const permissions = map(
    permission => (
      <li data-testid="missing-permission" key={permission}>
        {startCase(camelCase(permission))}
      </li>
    ),
    necessaryPermissions
  );
  const groups = useOmsSelector(TeammatesSelectors.getSignedInTeammateGroups);
  return (
    <div data-testid="notAllowed">
      <Message>
        <Typography variant="h3">You Shall Not Pass!</Typography>
        <Typography variant="body1">Uh oh, Gandalf is blocking the way!</Typography>
        <br />
        Your role{' '}
        {groups.length > 0 ? (
          <Typography variant="body1" data-testid="current-role">
            {map(
              g => (
                <li key={g.name}>{g.name}</li>
              ),
              groups
            )}
          </Typography>
        ) : null}
        is not allowed to access this page! Contact your supervisor if you need access.
        {necessaryPermissions.length > 0 ? (
          <Typography variant="body1" data-testid="missing-permissions">
            Missing permissions: {permissions}
          </Typography>
        ) : null}
        <Link to="/">
          <Typography>Back to Home</Typography>
        </Link>
      </Message>
      <Gandalf>
        <Fireball />
        <Skirt />
        <Sleeves />
        <Shoulders>
          <HandLeft />
          <HandRight />
        </Shoulders>
        <Head>
          <Hair />
          <Beard />
        </Head>
      </Gandalf>
    </div>
  );
};

export default NotAllowedComponent;
