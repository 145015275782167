import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { AdminPanelState, Printers } from './types';

export const fetchPrinters = createAsyncThunk<Printers>(
  'printers/fetchPrinters',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/printers/`);
      const printers: Printers = await response.json();
      return printers;
    } catch (err) {
      logSentryError('[fetchPrinters] fetch printers', err);
      throw err;
    }
  }
);

const initialState: AdminPanelState = {
  status: 'idle',
  printers: [],
  error: null,
};

const adminPanelSlice = createSlice({
  name: 'printers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPrinters.pending, draftState => {
        draftState.status = 'pending';
        draftState.printers = [];
        draftState.error = null;
      })
      .addCase(fetchPrinters.fulfilled, (draftState, action) => {
        const printers = action.payload;

        draftState.status = 'fulfilled';
        draftState.printers = printers;
      })
      .addCase(fetchPrinters.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = adminPanelSlice;

export default reducer;
