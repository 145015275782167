import { createSlice } from '@reduxjs/toolkit';

import { downloadExport, fetchDetailedExport, fetchExports } from './thunks';
import type { ExportsState } from './types';

const initialState: ExportsState = {
  exports: {
    status: 'idle',
    error: null,
    data: [],
  },
  detailedExports: {
    status: 'idle',
    error: null,
    data: {},
  },
  currentDownloads: {},
};

const exportsSlice = createSlice({
  name: 'exports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // fetchExports
      .addCase(fetchExports.pending, draftState => {
        draftState.exports.status = 'pending';
        draftState.exports.data = [];
        draftState.exports.error = null;
      })
      .addCase(fetchExports.fulfilled, (draftState, action) => {
        const exports = action.payload;

        draftState.exports.status = 'fulfilled';
        draftState.exports.data = exports;
      })
      .addCase(fetchExports.rejected, (draftState, action) => {
        draftState.exports.status = 'rejected';
        draftState.exports.error = action.error;
      })
      // fetchDetailedExport
      .addCase(fetchDetailedExport.pending, draftState => {
        draftState.detailedExports.status = 'pending';
        draftState.detailedExports.error = null;
      })
      .addCase(fetchDetailedExport.fulfilled, (draftState, action) => {
        const detailedExport = action.payload;
        const { pubkey } = detailedExport;

        draftState.detailedExports.status = 'fulfilled';
        draftState.detailedExports.data[pubkey] = detailedExport;
      })
      .addCase(fetchDetailedExport.rejected, (draftState, action) => {
        draftState.detailedExports.status = 'rejected';
        draftState.detailedExports.error = action.error;
      })
      // downloadExport
      .addCase(downloadExport.pending, (draftState, action) => {
        const { pubkey } = action.meta.arg;

        draftState.currentDownloads[pubkey] = {
          status: 'pending',
          error: null,
        };
      })
      .addCase(downloadExport.fulfilled, (draftState, action) => {
        const { pubkey } = action.meta.arg;

        draftState.currentDownloads[pubkey] = {
          status: 'fulfilled',
          error: null,
        };
      })
      .addCase(downloadExport.rejected, (draftState, action) => {
        const { pubkey } = action.meta.arg;

        draftState.currentDownloads[pubkey] = {
          status: 'rejected',
          error: action.error,
        };
      });
  },
});

const { reducer } = exportsSlice;

export default reducer;
