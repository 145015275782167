import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import initApiClient from './ApiClient';

const useApiClient = () => {
  const dispatch = useDispatch();
  return useMemo(() => initApiClient(dispatch), [dispatch]);
};

export default useApiClient;
