import { createSlice } from '@reduxjs/toolkit';

import {
  fetchCurrentSubscriptionDetail,
  fetchDynamicDiscountPercentage,
  fetchLogReasons,
  fetchShippingAddress,
  fetchSubscriptions,
  fetchSubscriptionsDetail,
  fetchSubscriptionStats,
} from './thunks';
import type { SubscriptionsState } from './types';

const initialState: SubscriptionsState = {
  subscriptionsList: [],
  customerSubscriptions: null,
  currentSubscription: null,
  logReasons: [],
  shippingAddress: null,
  stats: null,
  tooltipText: [],
  subscriptionDiscount: null,
  pages: {},
  status: 'idle',
  error: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSubscriptions.pending, draftState => {
        draftState.status = 'pending';
        draftState.subscriptionsList = [];
        draftState.pages = {};
        draftState.error = null;
      })
      .addCase(fetchSubscriptions.fulfilled, (draftState, action) => {
        const { subscriptions, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.subscriptionsList = subscriptions;
        draftState.pages = pages;
      })
      .addCase(fetchSubscriptions.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchCurrentSubscriptionDetail.pending, draftState => {
        draftState.status = 'pending';
        draftState.currentSubscription = null;
        draftState.error = null;
      })
      .addCase(fetchCurrentSubscriptionDetail.fulfilled, (draftState, action) => {
        const { currentSubscription } = action.payload;

        draftState.status = 'fulfilled';
        draftState.currentSubscription = currentSubscription;
      })
      .addCase(fetchCurrentSubscriptionDetail.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchSubscriptionsDetail.pending, draftState => {
        draftState.status = 'pending';
        draftState.customerSubscriptions = null;
        draftState.error = null;
      })
      .addCase(fetchSubscriptionsDetail.fulfilled, (draftState, action) => {
        const { subscriptionsDetail } = action.payload;

        draftState.status = 'fulfilled';
        draftState.customerSubscriptions = subscriptionsDetail;
      })
      .addCase(fetchSubscriptionsDetail.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchSubscriptionStats.pending, draftState => {
        draftState.status = 'pending';
        draftState.stats = null;
        draftState.error = null;
      })
      .addCase(fetchSubscriptionStats.fulfilled, (draftState, action) => {
        const stats = action.payload;

        draftState.status = 'fulfilled';
        draftState.stats = stats;
      })
      .addCase(fetchSubscriptionStats.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchLogReasons.pending, draftState => {
        draftState.status = 'pending';
        draftState.logReasons = [];
        draftState.error = null;
      })
      .addCase(fetchLogReasons.fulfilled, (draftState, action) => {
        const logReasons = action.payload;

        draftState.status = 'fulfilled';
        draftState.logReasons = logReasons;
      })
      .addCase(fetchLogReasons.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchShippingAddress.pending, draftState => {
        draftState.status = 'pending';
        draftState.shippingAddress = null;
        draftState.error = null;
      })
      .addCase(fetchShippingAddress.fulfilled, (draftState, action) => {
        const address = action.payload;

        draftState.status = 'fulfilled';
        draftState.shippingAddress = address;
      })
      .addCase(fetchShippingAddress.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchDynamicDiscountPercentage.pending, draftState => {
        draftState.status = 'pending';
        draftState.subscriptionDiscount = null;
        draftState.tooltipText = [];
        draftState.error = null;
      })
      .addCase(fetchDynamicDiscountPercentage.fulfilled, (draftState, action) => {
        const { roundedDiscount, tooltipText } = action.payload;

        draftState.status = 'fulfilled';
        draftState.subscriptionDiscount = roundedDiscount;
        draftState.tooltipText = tooltipText;
      })
      .addCase(fetchDynamicDiscountPercentage.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = subscriptionsSlice;

export default reducer;
