const getEnv = envKey => {
  switch (envKey) {
    case undefined:
      // if no key, return the whole env object.
      return import.meta.env;
    // Versions needs to be defaulted to a window object check if empty
    case 'REACT_APP_VERSION_BACKOFFICE':
      return import.meta.env?.[envKey] || window.GLOBAL_VERSION_BACKOFFICE;
    case 'REACT_APP_VERSION_API':
      return import.meta.env?.[envKey] || window.GLOBAL_VERSION_API;
    default: {
      return import.meta.env?.[envKey];
    }
  }
};

export default getEnv;
