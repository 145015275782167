import { useEffect } from 'react';

import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';

import * as AuthSelectors from 'dux/auth/selectors';
import * as FeatureFlagsActions from 'dux/featureFlags/slice';

const FeatureFlagsHandler = () => {
  const dispatch = useOmsDispatch();
  const isSignedIn = useOmsSelector(AuthSelectors.isSignedIn);

  useEffect(() => {
    // FF flag re-fetch after sign-in is done because some functionality could be only for certain users
    // FF flag re-fetch after sign-out is done for consistency with what someone would expect
    dispatch(FeatureFlagsActions.fetchFeatureFlags());
  }, [dispatch, isSignedIn]);

  return null;
};

export default FeatureFlagsHandler;
