import flow from 'lodash/fp/flow';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import reject from 'lodash/fp/reject';

import logSentryError from 'utils/sentry';

// Next statuses now available now come from backend
export const prodItemsSequentialUpdate = async (APIClient, proditems, status, reportError = true) =>
  Promise.all(
    map(async proditem => {
      if (
        proditem.status === 'packing' &&
        includes(status, [
          'produced',
          'buffered',
          'buffering',
          'processed',
          'prepacking',
          'prepacked',
          'packing',
        ])
      ) {
        return Promise.resolve();
      }
      const nextStatuses = map('target', proditem.next_statuses);
      if (includes(status, nextStatuses)) {
        return APIClient.patch(`/v1/backoffice/proditems/${proditem.pubkey}`, { status });
      }
      // we don't want to notify the API is the failed transition comes from the order detail screen
      if (reportError) {
        // this is already handled by the API but we want to be notified in sentry when it happens
        logSentryError(
          '[promiseProditemUpdate.js]',
          `Transition from ${proditem.status} to ${status} is not allowed by the API`
        );
      }
      return Promise.resolve();
    }, proditems)
  ).catch(err => {
    logSentryError(
      `[promiseProditemUpdate.js] Try sequential backend update prodItem to ${status}`,
      err
    );
    throw err;
  });

export const prodItemsBulkUpdate = async (APIClient, proditems, status, boxPubkey) => {
  const proditem_pubkeys = flow(map('pubkey'), reject(isEmpty))(proditems);

  if (isEmpty(proditem_pubkeys)) throw new Error('Cannot bulk update, empty prod items');

  return APIClient.patch(`/v1/backoffice/boxes/${boxPubkey}/proditems/`, {
    proditem_pubkeys,
    status: { to: status },
  });
};
