import isEmpty from 'lodash/fp/isEmpty';

const stringToBoolOrValue = s => {
  if (/true/i.test(s)) {
    return true;
  }
  if (/false/i.test(s) || isEmpty(s)) {
    return false;
  }
  return s;
};

export default stringToBoolOrValue;
