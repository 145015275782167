import './polyfills';

import './global.css';

import React from 'react';

import { createRoot } from 'react-dom/client';

import addSentry from './utils/addSentry';
import Root from './Root';
import unregister from './unregisterServiceWorker';

addSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);
unregister();
